import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Result,
  Row,
  Space,
  Switch,
} from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../api';
import { useSubmitForm } from '../../helpers/forms';
import { useLoadFormData } from '../../hooks/useLoadFormData';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import SelectWithApiData from '../SelectiWithApiData';
import FileUploadV2 from '../fileUploadV2/FileUploadV2';
import { TagsInput } from '../tags/TagsInput';
import OldHtmlTextPreviewModal from '../OldHtmlTextPreviewModal';
import BlockEditor from '../../blockeditor';

const ArticleForm = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      author: '',
      textV2: { JSON: {}, html: '' },
      text: '',
      title: '',
      slug: '',
      date: new Date(),
      type: null,
      tagsV2: [],
      image: null,
      isPublished: false,
    },
  });
  let navigate = useNavigate();
  const onSuccess = () => {
    message.success('Saved');
    navigate('/articles');
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];
  const oldText = watch('text');
  const textV2 = watch('textV2');

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.articles.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.articles.create,
    updateApiCall: Api.articles.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="default">
            <Link to="/articles">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <div className="white-container">
      <form onSubmit={handleSubmit(submit)}>
        <Helmet>
          <title>Create Article | {process.env.REACT_APP_PROJECT_NAME}</title>
        </Helmet>
        <Space size="middle" style={{ width: '100%' }} direction="vertical">
          <Row style={{ width: '100%' }} gutter={12}>
            <Col span={6}>
              <label htmlFor={'title'}>Title</label>
              <Controller
                control={control}
                render={({ field }) => <Input {...field} id={'title'} />}
                rules={{ required: true }}
                name="title"
              />
              <FieldErrorMessage errors={errors} name="title" />
            </Col>
            <Col span={6}>
              <label htmlFor={'author'}>author</label>
              <Controller
                control={control}
                render={({ field }) => <Input {...field} id={'author'} />}
                rules={{ required: false }}
                name="author"
              />
              <FieldErrorMessage errors={errors} name="author" />
            </Col>
            <Col span={2}>
              <label htmlFor={'isPublished'}>Published</label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Switch
                    onChange={field.onChange}
                    checked={field.value}
                    defaultChecked={field.value}
                    style={{ display: 'block', marginTop: 3 }}
                  />
                )}
                name="isPublished"
              />
              <FieldErrorMessage errors={errors} name="isPublished" />
            </Col>
          </Row>
          <Row style={{ width: '100%' }} gutter={12}>
            <Col span={6}>
              <label htmlFor={'title'}>Slug</label>
              <Controller
                control={control}
                render={({ field }) =>
                  field.value ? (
                    <div className="py-1 rounded-md text-base underline text-blue-500 line-clamp-1">
                      <a
                        target="_blank"
                        href={`${process.env.REACT_APP_WEBSITE_URL}/articles/${field.value}`}
                        rel="noreferrer"
                      >
                        {field.value}
                      </a>
                    </div>
                  ) : (
                    <Input disabled />
                  )
                }
                rules={{ required: false }}
                name="slug"
              />
              <FieldErrorMessage errors={errors} name="slug" />
            </Col>
            <Col span={6}>
              <label htmlFor={'date'}>Date</label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <DatePicker
                    format={'DD-MM-YYYY'}
                    showToday
                    style={{ display: 'block' }}
                    onChange={(v) => onChange(v?.toDate())}
                    value={value && dayjs(value)}
                  />
                )}
                rules={{ required: false }}
                name="date"
              />
              <FieldErrorMessage errors={errors} name="date" />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={6}>
              <label htmlFor={'type'}>Type (Category)</label>
              <Controller
                control={control}
                render={({ field }) => (
                  <SelectWithApiData
                    labelField="name"
                    {...field}
                    apiAddCall={Api.articleTypes.create}
                    apiFetchCall={Api.articleTypes.list}
                  />
                )}
                rules={{ required: true }}
                name="type"
              />
              <FieldErrorMessage errors={errors} name="type" />
            </Col>
            <Col span={6}>
              <label htmlFor={'tagsV2'}>Tags</label>
              <Controller
                control={control}
                render={({ field }) => <TagsInput {...field} id="tagsV2" />}
                name="tagsV2"
              />
              <FieldErrorMessage errors={errors} name="tagsV2" />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor={'image'}>Image</label>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field }) => <FileUploadV2 singleValue {...field} />}
                name="image"
              />
              <FieldErrorMessage errors={errors} name="image" />
            </Col>
          </Row>
          {oldText && (
            <Row>
              <OldHtmlTextPreviewModal text={oldText} />
            </Row>
          )}
        </Space>
        <div style={{ marginBottom: 10 }} />
      </form>

      <Row>
        <Col span={24}>
          <BlockEditor
            content={textV2?.html}
            onChange={(v: any) => {
              setValue('textV2', v);
            }}
          />
        </Col>
      </Row>

      <Button
        onClick={() => {
          handleSubmit(submit)();
        }}
        loading={loading || loadingSubmit}
        htmlType="submit"
        role={'submit'}
      >
        Submit
      </Button>
    </div>
  );
};

export default ArticleForm;
