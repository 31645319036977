import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import TextAlign from '@tiptap/extension-text-align';
import TextUnderline from '@tiptap/extension-underline';
import TextHighlight from '@tiptap/extension-highlight';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import customImage from '../image/extension';
import Youtube from '@tiptap/extension-youtube';
import Heading from '@tiptap/extension-heading';
import CharacterCounter from '@tiptap/extension-character-count';
import StarterKit from '@tiptap/starter-kit';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import History from '@tiptap/extension-history';

export const extensions = [
  customImage,
  Color.configure({ types: [TextStyle.name] }),
  TextStyle,
  TextAlign.configure({
    types: ['heading', 'paragraph', 'image'],
    alignments: ['left', 'center', 'right'],
    defaultAlignment: 'left',
  }),
  TextUnderline,
  TextHighlight.configure({
    multicolor: true,
  }),
  Link,
  StarterKit.configure({
    codeBlock: false,
    code: false,
    blockquote: false,
  }),
  // Image.configure({ inline: true }),
  CharacterCounter,
  Heading.configure({
    levels: [1, 2, 3],
  }),
  BulletList,
  ListItem,
  Youtube,
];
