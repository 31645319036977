import { Button, Col, Input, message, Result, Row, Space } from 'antd';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../api';
import { useSubmitForm } from '../../helpers/forms';
import { useLoadFormData } from '../../hooks/useLoadFormData';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { Helmet } from 'react-helmet';
import { TagsInput } from '../tags/TagsInput';
import PhoneArrayFieldSimple from '../PhoneArrayFieldSimple';

const VenueForm = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  let navigate = useNavigate();
  const phoneNumbersFieldArray = useFieldArray({ name: 'phones', control });
  const onSuccess = () => {
    message.success('Saved');
    navigate('/venues');
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.venues.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.venues.create,
    updateApiCall: Api.venues.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="default">
            <Link to="/venues">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="white-container" onSubmit={handleSubmit(submit)}>
      <Helmet>
        <title>Create Venue | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <Space size="middle" style={{ width: '100%' }} direction="vertical">
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={12}>
            <label htmlFor={'title'}>Name</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} id={'title'} />}
              rules={{ required: true }}
              name="title"
            />
            <FieldErrorMessage errors={errors} name="title" />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor={'tags'}>Tags</label>
            <Controller
              control={control}
              render={({ field }) => <TagsInput {...field} id="tags" />}
              name="tags"
            />
            <FieldErrorMessage errors={errors} name="tags" />
          </Col>
        </Row>
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={12}>
            <label htmlFor={'address'}>Address</label>
            <Controller
              control={control}
              render={({ field }) => (
                <Input.TextArea {...field} id={'address'} />
              )}
              name="address"
            />
            <FieldErrorMessage errors={errors} name="address" />
          </Col>
          <Col span={12}>
            <PhoneArrayFieldSimple
              control={control}
              errors={errors}
              fieldName="phones"
              useFieldArrayFields={phoneNumbersFieldArray}
            />
          </Col>
        </Row>
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={12}>
            <label htmlFor={'gpsCoordinates'}>GPS Coordinates</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} id={'gpsCoordinates'} />}
              name="gpsCoordinates"
            />
            <FieldErrorMessage errors={errors} name="gpsCoordinates" />
          </Col>
        </Row>
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={8}>
            <label htmlFor={'website'}>Website</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} id={'website'} />}
              name="website"
            />
            <FieldErrorMessage errors={errors} name="website" />
          </Col>
          <Col span={8}>
            <label htmlFor={'facebookUrl'}>Facebook</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} id={'facebookUrl'} />}
              name="facebookUrl"
            />
            <FieldErrorMessage errors={errors} name="facebookUrl" />
          </Col>
          <Col span={8}>
            <label htmlFor={'twitterUrl'}>Twitter</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} id={'twitterUrl'} />}
              name="twitterUrl"
            />
            <FieldErrorMessage errors={errors} name="twitterUrl" />
          </Col>
          <Col span={8}>
            <label htmlFor={'instagramUrl'}>Instagram</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} id={'instagramUrl'} />}
              name="instagramUrl"
            />
            <FieldErrorMessage errors={errors} name="instagramUrl" />
          </Col>
          <Col span={8}>
            <label htmlFor={'youtubeUrl'}>Youtube</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} id={'youtubeUrl'} />}
              name="youtubeUrl"
            />
            <FieldErrorMessage errors={errors} name="youtubeUrl" />
          </Col>
        </Row>
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={24}>
            <label htmlFor={'notes'}>Notes</label>
            <Controller
              control={control}
              render={({ field }) => <Input.TextArea {...field} id={'notes'} />}
              name="notes"
            />
            <FieldErrorMessage errors={errors} name="notes" />
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button
        loading={loading || loadingSubmit}
        htmlType="submit"
        role={'submit'}
      >
        Submit
      </Button>
    </form>
  );
};

export default VenueForm;
