import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Result,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import * as RRule from 'rrule';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../api';
import { useSubmitForm } from '../../helpers/forms';
import { useLoadFormData } from '../../hooks/useLoadFormData';
import { FieldErrorMessage } from '../FieldErrorMessage';
import FileUploadV2 from '../fileUploadV2/FileUploadV2';
import SelectWithApiData from '../SelectiWithApiData';
import { TagsInput } from '../tags/TagsInput';
import dayjs from 'dayjs';
import OldHtmlTextPreviewModal from '../OldHtmlTextPreviewModal';
import 'dayjs/locale/fr';
import BlockEditor from '../../blockeditor';

const defaultValue = {
  venue: null,
  tagsV2: [],
  text: '',
  isFree: false,
  publishingDate: new Date(),
  isPublished: true,
  textV2: { JSON: {}, html: '' },
  slug: '',
  image: null,
  type: null,
  price: undefined,
  photoLibrary: [],
  website: '',
  facebookLink: '',
  instagramLink: '',
  title: '',
  dateV2: {
    from: null,
    manualDates: [],
    to: null,
    isRecurring: false,
    allDay: false,
    repeatOnDays: [],
    byMonth: [],
    byWeekDay: [],
    interval: 0,
    count: 0,
    frequency: RRule.Frequency.DAILY,
    openingDate: null,
    recurrenceText: '',
  },
};
const EventForm = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: defaultValue,
  });
  let navigate = useNavigate();
  const onSuccess = () => {
    message.success('Saved');
    navigate('/events');
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const isFree = watch('isFree');
  const eventDate = watch('dateV2', { ...defaultValue.dateV2 });
  const TextV2 = watch('textV2', { ...defaultValue.textV2 });
  const oldText = watch('text');
  const dateV2 = watch('dateV2');
  const [recurrenceText, setRecurrenceText] = useState(''),
    recurrenceTextBuilder = ({
      months,
      days,
      until,
    }: {
      months?: number[];
      days?: number[];
      until: Date | null;
    }) => {
      const dayNames = [
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi',
          'Dimanche',
        ],
        monthNames = [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        value = ['Chaque'];
      if (days) {
        const length = days.length,
          _days = days.map((day) => dayNames[day]);
        if (length > 1) {
          const lastDay = `et ${_days.pop()}`;
          value.push(_days.join(', '), lastDay);
        } else {
          value.push(_days[0]);
        }
      }
      if (months) {
        const length = months.length,
          _months = months.map((month) => monthNames[month - 1]);
        if (days?.length) value.push('de');
        if (length > 1) {
          const lastMonth = `et ${_months.pop()}`;
          value.push(_months.join(', '), lastMonth);
        } else {
          value.push(_months[0]);
        }
      }
      if (value.length !== 1) {
        if (until)
          value.push(
            `jusqu'au ${dayjs(until).locale('fr').format('D MMMM YYYY')}`,
          );
        return value.join(' ');
      }
      return '';
    };

  const dateV2String = JSON.stringify(dateV2);
  useEffect(() => {
    if (dateV2.from && dateV2.frequency) {
      const rrule = new RRule.RRule({
        freq: dateV2.frequency,
        dtstart: new Date(dateV2.from),
        bymonth: dateV2.byMonth.length ? dateV2.byMonth : undefined,
        count: dateV2.count === 0 ? undefined : dateV2.count,
        until: dateV2.to && new Date(dateV2.to),
        byweekday: dateV2.byWeekDay.length
          ? dateV2.byWeekDay.map((weekDay) => RRule.Weekday.fromStr(weekDay))
          : undefined,
      });

      const text = recurrenceTextBuilder({
        months: rrule.options?.bymonth,
        days: rrule.options?.byweekday,
        until: rrule.options?.until,
      });
      setRecurrenceText(text);
      setValue('dateV2.recurrenceText', recurrenceText);
    }
  }, [dateV2String]);

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.events.single,
    defaultValues: {},
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.events.create,
    updateApiCall: Api.events.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="default">
            <Link to="/events">Back to list</Link>
          </Button>
        }
      />
    );
  return (
    <div className="white-container">
      <form onSubmit={handleSubmit(submit)}>
        <Helmet>
          <title>Create Event | {process.env.REACT_APP_PROJECT_NAME}</title>
        </Helmet>
        <Space size="middle" style={{ width: '100%' }} direction="vertical">
          <Row style={{ width: '100%' }} gutter={12}>
            <Col span={6}>
              <label htmlFor={'title'}>Title</label>
              <Controller
                control={control}
                render={({ field }) => <Input {...field} id={'title'} />}
                rules={{ required: true }}
                name="title"
              />
              <FieldErrorMessage errors={errors} name="title" />
            </Col>
            <Col span={6}>
              <label htmlFor={'slug'}>Slug</label>
              <Controller
                control={control}
                render={({ field }) =>
                  field.value ? (
                    <div className="py-1 rounded-md text-base underline text-blue-500 line-clamp-1">
                      <a
                        target="_blank"
                        href={`${process.env.REACT_APP_WEBSITE_URL}/events/${field.value}`}
                        rel="noreferrer"
                      >
                        {field.value}
                      </a>
                    </div>
                  ) : (
                    <Input disabled />
                  )
                }
                rules={{ required: false }}
                name="slug"
              />
              <FieldErrorMessage errors={errors} name="slug" />
            </Col>
            <Col span={6}>
              <label htmlFor={'publishingDate'}>Publishing Date</label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <DatePicker
                    format={'DD-MM-YYYY'}
                    showToday
                    style={{ display: 'block' }}
                    onChange={(v) => onChange(v?.toDate())}
                    value={value && dayjs(value)}
                  />
                )}
                rules={{ required: false }}
                name="publishingDate"
              />
              <FieldErrorMessage errors={errors} name="publishingDate" />
            </Col>
            <Col span={2}>
              <label htmlFor={'isPublished'}>Published</label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Switch
                    onChange={field.onChange}
                    checked={field.value}
                    defaultChecked={field.value}
                    style={{ display: 'block', marginTop: 3 }}
                  />
                )}
                name="isPublished"
              />
              <FieldErrorMessage errors={errors} name="isPublished" />
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <label htmlFor={'image'}>Image</label>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field }) => <FileUploadV2 singleValue {...field} />}
                name="image"
              />
              <FieldErrorMessage errors={errors} name="image" />
            </Col>
            <Col span={12}>
              <label htmlFor={'photoLibrary'}>Image Gallery</label>
              <Controller
                control={control}
                rules={{ required: false }}
                render={({ field }) => <FileUploadV2 limit={10} {...field} />}
                name="photoLibrary"
              />
              <FieldErrorMessage errors={errors} name="photoLibrary" />
            </Col>
          </Row>

          <Row style={{ width: '100%' }} gutter={12}>
            <Col span={2}>
              <label htmlFor={'isFree'}>Is Free</label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Switch
                    onChange={field.onChange}
                    checked={field.value}
                    style={{ display: 'block' }}
                  />
                )}
                name="isFree"
              />
              <FieldErrorMessage errors={errors} name="isFree" />
            </Col>

            {!isFree && (
              <Col span={6}>
                <label htmlFor={'price'}>Price</label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Input {...field} style={{ width: '100%' }} id="price" />
                  )}
                  name="price"
                />
                <FieldErrorMessage errors={errors} name="price" />
              </Col>
            )}
          </Row>

          <Row style={{ width: '100%' }} gutter={12}>
            <Col span={6}>
              <label htmlFor={'type'}>Type (Category)</label>
              <Controller
                control={control}
                render={({ field }) => (
                  <SelectWithApiData
                    {...field}
                    apiAddCall={Api.eventTypes.create}
                    apiFetchCall={Api.eventTypes.list}
                  />
                )}
                rules={{ required: true }}
                name="type"
              />
              <FieldErrorMessage errors={errors} name="type" />
            </Col>

            <Col span={6}>
              <label htmlFor={'venue'}>Venue</label>
              <Controller
                control={control}
                render={({ field }) => (
                  <SelectWithApiData
                    labelField="title"
                    {...field}
                    apiAddCall={Api.venues.create}
                    apiFetchCall={Api.venues.list}
                  />
                )}
                rules={{ required: true }}
                name="venue"
              />
              <FieldErrorMessage errors={errors} name="venue" />
            </Col>
            <Col span={6}>
              <label htmlFor={'tagsV2'}>Tags</label>
              <Controller
                control={control}
                render={({ field }) => <TagsInput {...field} id="tagsV2" />}
                name="tagsV2"
              />
              <FieldErrorMessage errors={errors} name="tagsV2" />
            </Col>
          </Row>
          <Row style={{ width: '100%' }} gutter={12}>
            <Col span={6}>
              <label htmlFor={'website'}>Website Link</label>
              <Controller
                control={control}
                render={({ field }) => <Input {...field} id={'website'} />}
                name="website"
                rules={{
                  validate: (value) => {
                    if (value) {
                      const regex = /^(https?:\/\/).*$/;
                      return (
                        regex.test(value) || 'Link must start with https://'
                      );
                    }
                  },
                }}
              />
              <FieldErrorMessage errors={errors} name="website" />
            </Col>
            <Col span={6}>
              <label htmlFor={'facebookLink'}>Facebook Link</label>
              <Controller
                control={control}
                render={({ field }) => <Input {...field} id={'facebookLink'} />}
                name="facebookLink"
                rules={{
                  validate: (value) => {
                    if (value) {
                      const regex = /^(https?:\/\/).*$/;
                      return (
                        regex.test(value) || 'Link must start with https://'
                      );
                    }
                  },
                }}
              />
              <FieldErrorMessage errors={errors} name="facebookLink" />
            </Col>
            <Col span={6}>
              <label htmlFor={'instagramLink'}>instagram Link</label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Input {...field} id={'instagramLink'} />
                )}
                name="instagramLink"
                rules={{
                  validate: (value) => {
                    if (value) {
                      const regex = /^(https?:\/\/).*$/;
                      return (
                        regex.test(value) || 'Link must start with https://'
                      );
                    }
                  },
                }}
              />
              <FieldErrorMessage errors={errors} name="instagramLink" />
            </Col>
          </Row>
          <Card title="Dates" bordered>
            <Row>
              <Col span={4}>
                <label htmlFor={'dateV2.openingDate'}>Opening Date</label>
                <Controller
                  defaultValue={null}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <DatePicker
                      format={
                        !eventDate?.allDay ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY'
                      }
                      showTime={!eventDate?.allDay}
                      style={{ display: 'block' }}
                      onChange={onChange}
                      value={value && dayjs(value)}
                    />
                  )}
                  name={'dateV2.openingDate'}
                  rules={{ required: true }}
                />

                <FieldErrorMessage errors={errors} name="dateV2.openingDate" />
              </Col>
            </Row>
            <Row style={{ width: '100%' }} gutter={12}>
              <Col span={12}>
                <label htmlFor={'dateV2.from'}>Date From-to</label>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <DatePicker.RangePicker
                      style={{ width: '100%' }}
                      format={
                        !eventDate?.allDay ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY'
                      }
                      showTime={!eventDate.allDay}
                      onChange={(v) => {
                        if (v) {
                          onChange({
                            ...value,
                            from: v[0],
                            to: dayjs(v[1])
                              .set('seconds', 0)
                              .set('milliseconds', 0),
                          });
                        }
                      }}
                      value={
                        value &&
                        value.from && [dayjs(value.from), dayjs(value.to)]
                      }
                      allowClear={false}
                    />
                  )}
                  name={'dateV2'}
                  rules={{
                    required: true,
                    validate: ({ from, to }) => {
                      return (!!from && !!to) || 'Dates from, to are required';
                    },
                  }}
                />

                <FieldErrorMessage errors={errors} name="dateV2" />
              </Col>
            </Row>
            <Row>
              <Col span={2}>
                <label htmlFor={'dateV2.allDay'}>All Day</label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Switch
                      onChange={field.onChange}
                      checked={field.value}
                      defaultChecked={field.value}
                      style={{ display: 'block' }}
                    />
                  )}
                  name="dateV2.allDay"
                />
                <FieldErrorMessage errors={errors} name="dateV2.allDay" />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Col span={24}>
                  <label htmlFor={'dateV2.isRecurring'}>Recurring Event</label>
                </Col>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Switch
                      onChange={field.onChange}
                      checked={field.value}
                      style={{ display: 'block' }}
                    />
                  )}
                  name="dateV2.isRecurring"
                />
                <FieldErrorMessage errors={errors} name="dateV2.isRecurring" />
              </Col>
            </Row>

            {eventDate?.isRecurring && (
              <Row gutter={20} style={{ marginTop: 10 }}>
                <Col span={24}>
                  <p style={{ fontSize: 18, marginTop: 20, color: 'red' }}>
                    {recurrenceText}
                  </p>
                </Col>
                <Col span={4}>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        style={{ width: '100%' }}
                        options={[
                          {
                            label: 'Daily',
                            value: RRule.Frequency.DAILY,
                          },
                          {
                            label: 'Weekly',
                            value: RRule.Frequency.WEEKLY,
                          },
                          {
                            label: 'Monthly',
                            value: RRule.Frequency.MONTHLY,
                          },
                          {
                            label: 'Yearly',
                            value: RRule.Frequency.YEARLY,
                          },
                        ]}
                        {...field}
                      />
                    )}
                    name={'dateV2.frequency'}
                  />

                  <FieldErrorMessage errors={errors} name="dateV2.frequency" />
                </Col>

                <Col span={8}>
                  <label>By Month</label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Checkbox.Group
                        options={[
                          { label: 'January', value: 1 },
                          { label: 'February', value: 2 },
                          { label: 'March', value: 3 },
                          { label: 'April', value: 4 },
                          { label: 'May', value: 5 },
                          { label: 'June', value: 6 },
                          { label: 'July', value: 7 },
                          { label: 'August', value: 8 },
                          { label: 'September', value: 9 },
                          { label: 'October', value: 10 },
                          { label: 'November', value: 11 },
                          { label: 'December', value: 12 },
                        ]}
                        defaultValue={[]}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                    name="dateV2.byMonth"
                  />
                  <FieldErrorMessage errors={errors} name="dateV2.byMonth" />
                </Col>
                <Col span={8}>
                  <label>By Week Day</label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Checkbox.Group
                        options={[
                          { label: 'Monday', value: 'MO' },
                          { label: 'Tuesday', value: 'TU' },
                          { label: 'Wednesday', value: 'WE' },
                          { label: 'Thursday', value: 'TH' },
                          { label: 'Friday', value: 'FR' },
                          { label: 'Saturday', value: 'SA' },
                          { label: 'Sunday', value: 'SU' },
                        ]}
                        defaultValue={[]}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                    name="dateV2.byWeekDay"
                  />
                  <FieldErrorMessage errors={errors} name="dateV2.byWeekDay" />
                </Col>
              </Row>
            )}

            {eventDate?.isRecurring && (
              <Row gutter={12} style={{ marginTop: 10 }}>
                <Col span={6}>
                  <label style={{ marginRight: 10 }} htmlFor={'dateV2.count'}>
                    Number Of Repeating
                  </label>
                  <FieldErrorMessage errors={errors} name="dateV2.count" />
                  {
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <InputNumber style={{ width: '100%' }} {...field} />
                      )}
                      name={'dateV2.count'}
                    />
                  }
                </Col>
              </Row>
            )}
          </Card>
        </Space>
        <div style={{ marginBottom: 10 }} />
      </form>
      <Row>
        {oldText && (
          <Row>
            <OldHtmlTextPreviewModal text={oldText} />
          </Row>
        )}
        <Col span={24}>
          <BlockEditor
            content={TextV2.html}
            onChange={(v: any) => {
              setValue('textV2', v);
            }}
          />
        </Col>
      </Row>

      <Button
        onClick={() => {
          handleSubmit(submit)();
        }}
        loading={loading || loadingSubmit}
        htmlType="submit"
        role={'submit'}
      >
        Submit
      </Button>
    </div>
  );
};

export default EventForm;
