import { mergeAttributes } from '@tiptap/core';
import {
  parseObjectStylesToString,
  parseStringStylesToObject,
} from '../../helpers';
import { ImageResize } from 'tiptap-extension-resize-image';

export interface ImageOptions {
  inline: boolean;
  allowBase64: boolean;
  HTMLAttributes: Record<string, any>;
}

const customImage = ImageResize.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      float: {
        default: 'none',
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'img',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },
  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
      setImageAlignment:
        (alignment) =>
        ({ commands, editor }) => {
          const attr = editor.getAttributes('image').style as
              | string
              | undefined,
            objectStyles = parseStringStylesToObject(attr),
            newStyle: Record<string, string | undefined> = { float: alignment };
          switch (alignment) {
            case 'left': {
              newStyle['padding-right'] = '20px';
              newStyle['padding-left'] = '0px';
              break;
            }
            case 'right': {
              newStyle['padding-left'] = '20px';
              newStyle['padding-right'] = '0px';

              break;
            }
            case 'center': {
              newStyle['margin-left'] = 'auto';
              newStyle['mrgin-right'] = 'auto';
              break;
            }
            default: {
              newStyle['padding-left'] = undefined;
              newStyle['padding-right'] = undefined;
              newStyle['margin-left'] = undefined;
              newStyle['margin-right'] = undefined;
              break;
            }
          }
          const style = parseObjectStylesToString(
            Object.assign(objectStyles, newStyle),
          );

          return commands.updateAttributes('image', {
            style,
            float: alignment,
          });
        },
    };
  },
}).configure({ inline: true });

export default customImage;
