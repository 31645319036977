import {
  ContactsOutlined,
  DashboardOutlined,
  FileOutlined,
  MailOutlined,
  PlusOutlined,
  TagOutlined,
  TagsOutlined,
  TeamOutlined,
} from '@ant-design/icons/lib';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Api } from './api';
import Dashboard from './components/Dashboard';
import PageForm from './components/PageForm';
import PageTable from './components/PageTable';
import EventForm from './components/events/EventForm';
import EventTable from './components/events/EventTable';
import UsersForm from './components/users/UsersCreate';
import UsersList from './components/users/UsersList';
import ArticleTable from './components/articles/ArticleTable';
import ArticleForm from './components/articles/ArticleForm';
import ArticleTypeTable from './components/articletypes/ArticleTypeTable';
import ArticleTypeForm from './components/articletypes/ArticleTypeForm';
import VenueTable from './components/venues/VenueTable';
import VenueForm from './components/venues/VenueForm';
import EventTypeTable from './components/eventtypes/EventTypeTable';
import EventTypeForm from './components/eventtypes/EventTypeForm';
import AdvertisementForm from './components/advertisements/AdvertisementForm';
import AdvertisementTable from './components/advertisements/AdvertisementTable';
import FolderTable from './components/folders/FolderTable';
import FolderForm from './components/folders/FolderForm';
import { ListIcon } from 'lucide-react';
import PeopleTable from './components/peoples/PeopleTable';
import PeopleForm from './components/peoples/PeopleForm';
import FileTable from './components/files/FileTable';
import FileForm from './components/files/FileForm';
import NewsletterTable from './components/newsletter/NewsletterTable';
import ContactFormTable from './components/contactForm/ContactFormTable';
import ContactFormForm from './components/contactForm/ContactFormForm';

export const Routes: any = [
  {
    title: 'Dashboard',
    component: Dashboard,
    icon: DashboardOutlined,
    hideFromMenu: false,
    breadcrumb: 'I love sandwiches',
    path: '/',
    isHomePage: true,
  },

  {
    title: 'Users',
    icon: <TeamOutlined />,
    dataRoute: Api.users.statistics,
    path: '/users',
    buttons: [
      <Link key="create" to="/users/create">
        <Button type="default" icon={<PlusOutlined />}>
          Add User
        </Button>
      </Link>,
    ],
    component: UsersList,
    children: [
      {
        title: 'Create',
        icon: <PlusOutlined />,
        path: '/users/create',
        component: UsersForm,
      },

      {
        title: 'Update',
        hideFromMenu: true,
        path: '/users/update/:id',
        component: UsersForm,
      },
    ],
  },
  {
    title: 'Advertisements',
    icon: <TagOutlined />,
    dataRoute: Api.venues.statistics,
    path: '/advertisements',
    buttons: [
      <Link key="create" to="/advertisements/create">
        <Button type="default" icon={<PlusOutlined />}>
          Add Advertisement
        </Button>
      </Link>,
    ],
    component: AdvertisementTable,
    children: [
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/advertisements/update/:id',
        component: AdvertisementForm,
      },
      {
        title: 'Create',
        icon: <PlusOutlined />,
        path: '/advertisements/create',
        component: AdvertisementForm,
      },
    ],
  },
  {
    title: 'Events',
    icon: <TagOutlined />,
    dataRoute: Api.events.statistics,
    path: '/events',
    buttons: [
      <Link key="create" to="/events/create">
        <Button type="default" icon={<PlusOutlined />}>
          Add Event
        </Button>
      </Link>,
    ],
    component: EventTable,
    children: [
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/events/update/:id',
        component: EventForm,
      },
      {
        title: 'Create',
        icon: <PlusOutlined />,
        path: '/events/create',
        component: EventForm,
      },
      {
        title: 'Event Types',
        hideFromMenu: false,
        path: '/events/types',
        component: EventTypeTable,
        children: [
          {
            title: 'Create',
            hideFromMenu: true,
            icon: <PlusOutlined />,
            path: '/events/types/create',
            component: EventTypeForm,
          },
        ],
        buttons: [
          <Link key="create" to="/events/types/create">
            <Button type="default" icon={<PlusOutlined />}>
              Add Event Type
            </Button>
          </Link>,
        ],
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/events/types/update/:id',
        component: EventTypeForm,
      },
    ],
  },
  {
    title: 'Venues',
    icon: <TagOutlined />,
    dataRoute: Api.venues.statistics,
    path: '/venues',
    buttons: [
      <Link key="create" to="/venues/create">
        <Button type="default" icon={<PlusOutlined />}>
          Add Venue
        </Button>
      </Link>,
    ],
    component: VenueTable,
    children: [
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/venues/update/:id',
        component: VenueForm,
      },
      {
        title: 'Create',
        icon: <PlusOutlined />,
        path: '/venues/create',
        component: VenueForm,
      },
    ],
  },

  {
    title: 'Articles',
    icon: <TagOutlined />,
    dataRoute: Api.articles.statistics,
    path: '/articles',
    buttons: [
      <Link key="create" to="/articles/create">
        <Button type="default" icon={<PlusOutlined />}>
          Add Article
        </Button>
      </Link>,
    ],
    component: ArticleTable,
    children: [
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/articles/update/:id',
        component: ArticleForm,
      },
      {
        title: 'Create',
        icon: <PlusOutlined />,
        path: '/articles/create',
        component: ArticleForm,
      },
      {
        title: 'Article Types',
        hideFromMenu: false,
        path: '/articles/types',
        component: ArticleTypeTable,
        children: [
          {
            title: 'Create',
            hideFromMenu: true,
            icon: <PlusOutlined />,
            path: '/articles/types/create',
            component: ArticleTypeForm,
          },
        ],
        buttons: [
          <Link key="create" to="/articles/types/create">
            <Button type="default" icon={<PlusOutlined />}>
              Add Article Type
            </Button>
          </Link>,
        ],
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/articles/types/update/:id',
        component: ArticleTypeForm,
      },
    ],
  },

  {
    title: 'Dossier',
    icon: <TagOutlined />,
    dataRoute: Api.folders.statistics,
    path: '/folders',
    buttons: [
      <Link key="create" to="/folders/create">
        <Button type="default" icon={<PlusOutlined />}>
          Add Dossier
        </Button>
      </Link>,
    ],
    component: FolderTable,
    children: [
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/folders/update/:id',
        component: FolderForm,
      },
      {
        title: 'Create',
        icon: <PlusOutlined />,
        path: '/folders/create',
        component: FolderForm,
      },
    ],
  },
  {
    title: 'Newsletter',
    icon: <MailOutlined />,
    dataRoute: Api.newsletter.statistics,
    path: '/newsletter',
    component: NewsletterTable,
  },
  {
    title: 'Contact',
    icon: <ContactsOutlined />,
    dataRoute: Api.contact.statistics,
    path: '/contact',
    component: ContactFormTable,
    children: [
      {
        title: 'View',
        hideFromMenu: true,
        path: '/contact/single/:id',
        component: ContactFormForm,
      },
    ],
  },
  {
    title: 'People',
    icon: <ListIcon />,
    dataRoute: Api.peoples.statistics,
    path: '/peoples',
    buttons: [
      <Link key="create" to="/peoples/create">
        <Button type="default" icon={<PlusOutlined />}>
          Add People
        </Button>
      </Link>,
    ],
    component: PeopleTable,
    children: [
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/peoples/single/:id',
        component: PeopleForm,
      },
      {
        title: 'Create',
        icon: <PlusOutlined />,
        path: '/peoples/create',
        component: PeopleForm,
      },
    ],
  },
  {
    title: 'Pages',
    icon: <TagsOutlined />,
    buttons: [],
    path: '/pages',
    dataRoute: Api.pages.statistics,
    component: PageTable,
    children: [
      {
        title: 'Page Management',
        hidePageHeader: true,
        hideFromMenu: true,
        path: '/pages/update/:id',
        component: PageForm,
      },
    ],
  },
  {
    title: 'Files',
    icon: <FileOutlined />,
    buttons: [
      <Link key="create" to="/files/create">
        <Button type="default" icon={<PlusOutlined />}>
          Add File
        </Button>
      </Link>,
    ],
    path: '/files',
    dataRoute: Api.files.statistics,
    component: FileTable,
    children: [
      {
        title: 'File Management',
        hideFromMenu: true,
        path: '/files/update/:id',
        component: FileForm,
      },
      {
        title: 'Create',
        icon: <PlusOutlined />,
        path: '/files/create',
        component: FileForm,
      },
    ],
  },
];
