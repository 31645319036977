import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  ItalicOutlined,
  RedoOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UndoOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Editor } from '@tiptap/react';

export type HeaderItem = {
  name: string;
  icon: any;
  disabled: boolean;
  isActive: () => boolean;
  onClick: () => void;
};

export const History = (editor: Editor) => [
    {
      name: 'undo',
      icon: UndoOutlined,
      disabled: !editor.can().chain().undo().run(),
      isActive: () => editor.isActive('undo'),
      onClick: () => editor.chain().undo().run(),
    },
    {
      name: 'redo',
      icon: RedoOutlined,
      disabled: !editor.can().chain().redo().run(),
      isActive: () => editor.isActive('redo'),
      onClick: () => editor.chain().redo().run(),
    },
  ],
  Text = (editor: Editor) => [
    {
      name: 'bold',
      icon: BoldOutlined,
      disabled: !editor.can().chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
      onClick: () => editor.chain().focus().toggleBold().run(),
    },
    {
      name: 'italic',
      icon: ItalicOutlined,
      disabled: !editor.can().chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
      onClick: () => editor.chain().focus().toggleItalic().run(),
    },
    {
      name: 'underline',
      icon: UnderlineOutlined,
      disabled: !editor.can().chain().focus().toggleUnderline().run(),
      isActive: () => editor.isActive('underline'),
      onClick: () => editor.chain().focus().toggleUnderline().run(),
    },
    {
      name: 'strikethrough',
      icon: StrikethroughOutlined,
      disabled: !editor.can().chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive('strikethrough'),
      onClick: () => editor.chain().focus().toggleStrike().run(),
    },
    {
      name: 'bulletList',
      icon: UnorderedListOutlined,
      disabled: !editor.can().chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive('bulletList'),
      onClick: () => editor.chain().focus().toggleBulletList().run(),
    },
  ],
  Alignments = (editor: Editor) => [
    {
      name: 'alignLeft',
      icon: AlignLeftOutlined,
      disabled: editor.isActive('image')
        ? editor.isActive('image', { float: 'left' })
        : editor.isActive({ textAlign: 'left' }),
      isActive: () =>
        editor.isActive('image')
          ? editor.isActive('image', { float: 'left' })
          : editor.isActive({ textAlign: 'left' }),
      onClick: () =>
        editor.isActive('image')
          ? editor.chain().focus().setImageAlignment('left').run()
          : editor.chain().focus().setTextAlign('left').run(),
    },
    {
      name: 'alignCenter',
      icon: AlignCenterOutlined,
      disabled: editor.isActive('image')
        ? editor.isActive('image', { float: 'center' })
        : editor.isActive({ textAlign: 'center' }),
      isActive: () =>
        editor.isActive('image')
          ? editor.isActive('image', { float: 'center' })
          : editor.isActive({ textAlign: 'center' }),
      onClick: () =>
        editor.isActive('image')
          ? editor.chain().focus().setImageAlignment('center').run()
          : editor.chain().focus().setTextAlign('center').run(),
    },
    {
      name: 'alignRight',
      icon: AlignRightOutlined,
      disabled: editor.isActive('image')
        ? editor.isActive('image', { float: 'right' })
        : editor.isActive({ textAlign: 'right' }),
      isActive: () =>
        editor.isActive('image')
          ? editor.isActive('image', { float: 'right' })
          : editor.isActive({ textAlign: 'right' }),
      onClick: () =>
        editor.isActive('image')
          ? editor.chain().focus().setImageAlignment('right').run()
          : editor.chain().focus().setTextAlign('right').run(),
    },
  ];

export default function toHeaderItem(items: HeaderItem[]) {
  return items.map((item) => (
    <button
      key={item.name}
      onClick={item.onClick}
      className={'p-1'}
      disabled={item.disabled}
    >
      <span
        className={`${item.isActive() ? 'bg-slate-700' : ''}  rounded-sm not:disabled:hover:bg-slate-600 disabled:cursor-not-allowed p-1`}
      >
        <item.icon style={{ color: item.disabled ? 'grey' : 'white' }} />
      </span>
    </button>
  ));
}
