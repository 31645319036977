import moment from 'moment';

export const Helpers = {
  isProduction: process.env.NODE_ENV === 'production',
  formatDate: (date: Date): string => moment(date).format('DD/MM/YYYY'),
  formatDateFromNow: (date: Date): string => moment(date).fromNow(),
  formatDateFull: (date: Date): string =>
    moment(date).format('DD/MM/YYYY HH:mm:ss'),
};

export const parseStringStylesToObject = (str?: string) => {
  if (!str) return {};
  return str.split(';').reduce<Record<string, string>>((acc, style) => {
    if (style.trim() !== '') {
      const [property, value] = style.split(':').map((part) => part.trim());
      acc[property] = value;
    }
    return acc;
  }, {});
};

export const parseObjectStylesToString = (obj?: Record<string, string>) => {
  if (!obj) return '';
  return Object.keys(obj)
    .reduce<string[]>((acc, curr) => {
      if (curr) acc.push(`${curr}: ${obj[curr]};`);
      return acc;
    }, [])
    .join(' ');
};
