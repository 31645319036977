import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTrackedState } from 'reactive-react-redux';
import './App.css';
import { AppLayout } from './components/Layout';
import { Login } from './components/Login';
import { PageNotFound } from './components/PageNotFound';
import { Routes as RoutesDefinition } from './routes';
import { State } from './store';

function App() {
  return (
    <Routes>
      <Route element={<Login />} path="/login" />
      <Route
        path="/"
        element={
          <RequireAuth>
            <AppLayout />
          </RequireAuth>
        }
      >
        {RoutesDefinition.map((route: any) => (
          <>
            <Route
              key={route.path}
              path={route.path}
              // index={true}
              element={React.createElement(route.component)}
            ></Route>
            {route.children?.map((internalRoute: any) => (
              <>
                <Route
                  key={internalRoute.path}
                  path={internalRoute.path}
                  element={React.createElement(internalRoute.component)}
                />
                {internalRoute.children?.map((internalSubRoute: any) => (
                  <Route
                    key={internalSubRoute.path}
                    path={internalSubRoute.path}
                    element={React.createElement(internalSubRoute.component)}
                  />
                ))}
              </>
            ))}
          </>
        ))}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
function RequireAuth({ children }: any) {
  const state = useTrackedState<State>();
  return state.isLoggedIn ? children : <Navigate to={'/login'} />;
}

export default App;
