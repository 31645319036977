import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../Table';
import { Api } from '../../api';
import { Space } from 'antd';
import BasicTableFilter from '../BasicTableFilter';
import { Helmet } from 'react-helmet';

const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      width: '20%',
    },
  ],
  filterDefinition = [
    {
      key: 'title',
      type: 'search',
      label: 'Title',
    },
  ];

const FolderTable = () => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Helmet>
        <title>Folders Table | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <Table
        tableId={'foldersTable'}
        deleteApiCall={Api.folders.delete}
        allowDelete={true}
        restoreApiCall={Api.folders.restore}
        actions={(record: any) => (
          <Link to={`/folders/update/${record._id}`}>View</Link>
        )}
        filterDefinitions={filterDefinition}
        Filter={BasicTableFilter}
        apiCall={Api.folders.table}
        columns={columns}
      />
    </Space>
  );
};

export default FolderTable;
