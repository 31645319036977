import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../Table';
import { Api } from '../../api';
import { Image, Space } from 'antd';
import BasicTableFilter from '../BasicTableFilter';
import { Helmet } from 'react-helmet';
import { ColumnsType } from 'antd/lib/table';
import { ImagesHelpers } from '../../helpers/images';

const columns: ColumnsType = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: true,
    width: '20%',
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    sorter: true,
    width: '20%',
    render: (value) => {
      return (
        <Image
          width={100}
          height={100}
          style={{ objectFit: 'cover' }}
          src={ImagesHelpers.formatUrl(value)}
        />
      );
    },
  },
];
const filterDefinition = [
  {
    key: 'title',
    type: 'search',
    label: 'Title',
  },
];
const AdvertisementTable = () => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Helmet>
        <title>
          Advertisements Table | {process.env.REACT_APP_PROJECT_NAME}
        </title>
      </Helmet>
      <Table
        tableId={'advertisementsTable'}
        deleteApiCall={Api.advertisements.delete}
        allowDelete={true}
        restoreApiCall={Api.advertisements.restore}
        actions={(record: any) => (
          <Link to={`/advertisements/update/${record._id}`}>View</Link>
        )}
        Filter={BasicTableFilter}
        filterDefinitions={filterDefinition}
        apiCall={Api.advertisements.table}
        columns={columns}
      />
    </Space>
  );
};

export default AdvertisementTable;
