import React from 'react';
import { ConfigProvider, Spin } from 'antd';
import { Provider } from 'reactive-react-redux';
import ReactDOM from 'react-dom/client';
import './index.css';

import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  //@ts-ignore
  <Provider store={store}>
    <Helmet>
      <title>{process.env.REACT_APP_PROJECT_NAME}</title>
    </Helmet>
    <PersistGate loading={<Spin />} persistor={persistor}>
      <ConfigProvider
        theme={{ token: { colorPrimary: '#D02E26', colorIcon: '#D02E26' } }}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
