import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Result,
  Row,
  Select,
  Space,
} from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../api';
import { useSubmitForm } from '../../helpers/forms';
import { useLoadFormData } from '../../hooks/useLoadFormData';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { Helmet } from 'react-helmet';
import FileUploadV2 from '../fileUploadV2/FileUploadV2';
import dayjs from 'dayjs';

type AdvertisementLocationBuilderParams = {
  value: string;
  isHorizontal?: boolean;
  isVertical?: boolean;
};

const advertisementLocationBuilder = ({
  value,
  isHorizontal,
  isVertical,
}: AdvertisementLocationBuilderParams) => {
  return {
    label: `${isHorizontal ? 'Horizontal ' : isVertical ? 'Vertical ' : ''}${value
      .split('_')
      .map(
        (value) =>
          value.charAt(0).toUpperCase() + value.substring(1).toLowerCase(),
      )
      .join(' ')}`,
    value,
  };
};

export const ADVERTISEMENT_LOCATIONS = {
  HOME_TOP: advertisementLocationBuilder({
    value: 'HOME_TOP',
    isHorizontal: true,
  }),
  HOME_BOTTOM: advertisementLocationBuilder({
    value: 'HOME_BOTTOM',
    isHorizontal: true,
  }),
  EVENT_TOP: advertisementLocationBuilder({
    value: 'EVENT_TOP',
    isHorizontal: true,
  }),
  EVENT_LEFT: advertisementLocationBuilder({ value: 'EVENT_LEFT' }),
  EVENT_RIGHT: advertisementLocationBuilder({
    value: 'EVENT_RIGHT',
    isVertical: true,
  }),
  EVENT_SINGLE_TOP: advertisementLocationBuilder({
    value: 'EVENT_SINGLE_TOP',
    isHorizontal: true,
  }),
  EVENT_SINGLE_1: advertisementLocationBuilder({
    value: 'EVENT_SINGLE_1',
    isVertical: true,
  }),
  EVENT_SINGLE_2: advertisementLocationBuilder({
    value: 'EVENT_SINGLE_2',
    isVertical: true,
  }),
  ARTICLE_TOP: advertisementLocationBuilder({
    value: 'ARTICLE_TOP',
    isHorizontal: true,
  }),
  ARTICLE_SINGLE_TOP: advertisementLocationBuilder({
    value: 'ARTICLE_SINGLE_TOP',
    isHorizontal: true,
  }),
  ARTICLE_SINGLE_1: advertisementLocationBuilder({
    value: 'ARTICLE_SINGLE_1',
    isVertical: true,
  }),
  ARTICLE_SINGLE_2: advertisementLocationBuilder({
    value: 'ARTICLE_SINGLE_2',
    isVertical: true,
  }),
  DOSSIER_TOP: advertisementLocationBuilder({
    value: 'DOSSIER_TOP',
    isHorizontal: true,
  }),
  DOSSIER_SINGLE_TOP: advertisementLocationBuilder({
    value: 'DOSSIER_SINGLE_TOP',
    isHorizontal: true,
  }),
  VISITES_GUIDEES_TOP: advertisementLocationBuilder({
    value: 'VISITES_GUIDEES_TOP',
    isHorizontal: true,
  }),
  BEIRUT_ART_DAYS_TOP: advertisementLocationBuilder({
    value: 'BEIRUT_ART_DAYS_TOP',
    isHorizontal: true,
  }),
};

type advertisementForm = {
  title: string;
  location: string[];
  image: string;
  publishingDate: {
    from: Date;
    to: Date;
  };
  displayDuration?: number;
  url: string;
};
const AdvertisementForm = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<advertisementForm>();
  let navigate = useNavigate();
  const onSuccess = () => {
    message.success('Saved');
    navigate('/advertisements');
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.advertisements.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.advertisements.create,
    updateApiCall: Api.advertisements.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="default">
            <Link to="/advertisements">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="white-container" onSubmit={handleSubmit(submit)}>
      <Helmet>
        <title>
          Create Advertisement | {process.env.REACT_APP_PROJECT_NAME}
        </title>
      </Helmet>
      <Space size="middle" style={{ width: '100%' }} direction="vertical">
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={6}>
            <label htmlFor={'title'}>Title</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} id={'title'} />}
              rules={{ required: true }}
              name="title"
            />
            <FieldErrorMessage errors={errors} name="title" />
          </Col>
        </Row>
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={6}>
            <label htmlFor={'location'}>Location</label>
            <Controller
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  mode="multiple"
                  style={{ width: '100%' }}
                  options={Object.values(ADVERTISEMENT_LOCATIONS)}
                />
              )}
              rules={{ required: true }}
              name="location"
            />
            <FieldErrorMessage errors={errors} name="location" />
          </Col>
        </Row>
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={6}>
            <label htmlFor={'image'}>Image</label>
            <Controller
              control={control}
              render={({ field }) => <FileUploadV2 singleValue {...field} />}
              rules={{ required: true }}
              name="image"
            />
            <FieldErrorMessage errors={errors} name="image" />
          </Col>
          <Col span={6}>
            <label htmlFor={'url'}>URL</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} />}
              rules={{ required: true }}
              name="url"
            />
            <FieldErrorMessage errors={errors} name="url" />
          </Col>
        </Row>
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={6}>
            <label htmlFor={'publishingDate'}>Publishing Date</label>
            <Controller
              control={control}
              render={({ field }) => (
                <DatePicker.RangePicker
                  onChange={(v) => {
                    if (v) field.onChange({ from: v[0], to: v[1] });
                  }}
                  value={
                    field.value && [
                      dayjs(field.value.from),
                      dayjs(field.value.to),
                    ]
                  }
                />
              )}
              rules={{ required: true }}
              name="publishingDate"
            />
            <FieldErrorMessage errors={errors} name="publishingDate" />
          </Col>
        </Row>
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={6}>
            <label htmlFor={'displayDuration'}>Display Duration</label>
            <Controller
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  style={{ width: '100%' }}
                  min={2}
                  suffix="Seconds"
                />
              )}
              rules={{ required: false }}
              name="displayDuration"
            />
            <FieldErrorMessage errors={errors} name="displayDuration" />
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button
        loading={loading || loadingSubmit}
        htmlType="submit"
        role={'location'}
      >
        Submit
      </Button>
    </form>
  );
};

export default AdvertisementForm;
