import { Button, Col, Input, message, Row, Select, Space } from 'antd';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../api';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { Helmet } from 'react-helmet';

const UsersForm = () => {
  let { id } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
  } = useForm();
  let navigate = useNavigate();
  let password: any = useRef({});
  password.current = watch('password', '');
  const onSubmit = (data: any) => {
    if (!id) {
      Api.users.create(data).then((res) => {
        message.success('User Created');
        navigate('/users');
      });
    } else {
      Api.users.update({ id: id, ...data }).then((res) => {
        message.success('User Saved');
      });
    }
    // if(data.password!==data.confirmPassword){
    //     return message.error("")    // }
  };

  useEffect(() => {
    if (id) {
      Api.users.single(id).then((res) => {
        reset(res.data.data);
      });
    }
  }, [id, reset]);

  return (
    <form className="white-container" onSubmit={handleSubmit(onSubmit)}>
      <Space size="middle" style={{ width: '100%' }} direction="vertical">
        <Helmet>
          <title>Create User | {process.env.REACT_APP_PROJECT_NAME}</title>
        </Helmet>
        <Row gutter={12}>
          <Col span={6}>
            <label htmlFor={'firstName'}>First Name</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} />}
              rules={{ required: true }}
              name="firstName"
            />
            <FieldErrorMessage errors={errors} name="firstName" />
          </Col>
          <Col span={6}>
            <label htmlFor={'lastName'}>Last Name</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} />}
              rules={{ required: true }}
              name="lastName"
            />
            <FieldErrorMessage errors={errors} name="lastName" />
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={12}>
            <label htmlFor={'email'}>Email</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} />}
              rules={{
                required: true,
                validate: (value) => {
                  const strongRegex = new RegExp(
                    /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  );
                  return strongRegex.test(value) || 'Invalid email format';
                },
              }}
              name="email"
            />
            <FieldErrorMessage errors={errors} name="email" />
          </Col>
        </Row>

        {!id && (
          <Row gutter={6}>
            <Col span={6}>
              <label htmlFor={'password'}>Password</label>
              <Controller
                control={control}
                render={({ field }) => <Input.Password {...field} />}
                rules={{
                  required: true,
                  validate: (value) => {
                    const strongRegex = new RegExp(
                      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})',
                    );
                    return (
                      strongRegex.test(value) ||
                      'Password must be at least 6 character, containing an uppercase, lowercase a number and a special character'
                    );
                  },
                }}
                name="password"
              />
              <FieldErrorMessage errors={errors} name="password" />
            </Col>
            <Col span={6}>
              <label htmlFor={'confirmPassword'}>Confirm Password</label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Input.Password {...field} id={'confirmPassword'} />
                )}
                rules={{
                  validate: (value) =>
                    value === password.current || 'The passwords do not match',
                }}
                name="confirmPassword"
              />
              <FieldErrorMessage errors={errors} name="confirmPassword" />
            </Col>
          </Row>
        )}
        <Row gutter={8}>
          <Col span={6}>
            <label htmlFor={'gender'}>Gender</label>
            <Controller
              control={control}
              render={({ field }) => (
                <Select {...field} style={{ width: '100%' }}>
                  <Select.Option value={'Male'}>Male</Select.Option>
                  <Select.Option value={'Female'}>Female</Select.Option>
                </Select>
              )}
              rules={{ required: true }}
              name="gender"
            />
            <FieldErrorMessage errors={errors} name="gender" />
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button htmlType="submit" role={'submit'}>
        Submit
      </Button>
    </form>
  );
};

export default UsersForm;
