import { Button, Col, Input, message, Result, Row, Space } from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../api';
import { useSubmitForm } from '../../helpers/forms';
import { useLoadFormData } from '../../hooks/useLoadFormData';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { Helmet } from 'react-helmet';
import SelectWithApiData from '../SelectiWithApiData';
import FileUploadV2 from '../fileUploadV2/FileUploadV2';
import OldHtmlTextPreviewModal from '../OldHtmlTextPreviewModal';
import BlockEditor from '../../blockeditor';

const FolderForm = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm();
  let navigate = useNavigate();
  const onSuccess = () => {
    message.success('Saved');
    navigate('/folders');
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.folders.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.folders.create,
    updateApiCall: Api.folders.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });
  const TextV2 = watch('textV2', { JSON: {}, html: '' });
  const oldText = watch('text');

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="default">
            <Link to="/folders">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <div className="white-container">
      <form onSubmit={handleSubmit(submit)}>
        <Helmet>
          <title>Create Folder | {process.env.REACT_APP_PROJECT_NAME}</title>
        </Helmet>
        <Space size="middle" style={{ width: '100%' }} direction="vertical">
          <Row style={{ width: '100%' }} gutter={12}>
            <Col span={6}>
              <label htmlFor={'title'}>Title</label>
              <Controller
                control={control}
                render={({ field }) => <Input {...field} id={'title'} />}
                rules={{ required: true }}
                name="title"
              />
              <FieldErrorMessage errors={errors} name="title" />
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <label htmlFor={'thumbnail'}>Thumbnail</label>
              <Controller
                control={control}
                render={({ field }) => <FileUploadV2 {...field} singleValue />}
                rules={{ required: true }}
                name="thumbnail"
              />
              <FieldErrorMessage errors={errors} name="thumbnail" />
            </Col>
            <Col span={4}>
              <label htmlFor={'banner'}>Banner</label>
              <Controller
                control={control}
                render={({ field }) => <FileUploadV2 {...field} singleValue />}
                rules={{ required: false }}
                name="banner"
              />
              <FieldErrorMessage errors={errors} name="banner" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label htmlFor={'type'}>Articles</label>
              <Controller
                control={control}
                render={({ field }) => (
                  <SelectWithApiData
                    multiValue
                    {...field}
                    labelField="title"
                    apiAddCall={Api.articles.create}
                    apiFetchCall={Api.articles.list}
                  />
                )}
                rules={{ required: false }}
                name="articles"
              />
              <FieldErrorMessage errors={errors} name="articles" />
            </Col>
          </Row>
        </Space>
      </form>
      <Row style={{ marginTop: 20 }}>
        {oldText && (
          <Row>
            <OldHtmlTextPreviewModal text={oldText} />
          </Row>
        )}
        <Col span={24}>
          <BlockEditor
            content={TextV2.html}
            onChange={(v: any) => {
              setValue('textV2', v);
            }}
          />
        </Col>
      </Row>

      <Button
        onClick={() => {
          handleSubmit(submit)();
        }}
        loading={loading || loadingSubmit}
        htmlType="submit"
        role={'submit'}
      >
        Submit
      </Button>
    </div>
  );
};

export default FolderForm;
