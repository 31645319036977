import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../Table';
import { Api } from '../../api';
import { Image, Space, TableColumnsType } from 'antd';
import BasicTableFilter from '../BasicTableFilter';
import { Helmet } from 'react-helmet';
import { ImagesHelpers } from '../../helpers/images';
import { renderTableDate } from '../../helpers/table';

const columns: TableColumnsType = [
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',

    render: (value: string) => {
      return (
        <Image
          width={100}
          height={100}
          style={{ objectFit: 'cover' }}
          src={ImagesHelpers.formatUrl(value)}
        />
      );
    },
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: true,
  },
  {
    title: 'Venue',
    dataIndex: ['venue', 'title'],
  },
  {
    title: 'Type',
    dataIndex: ['type', 'name'],
  },
  {
    title: 'Published',
    dataIndex: 'isPublished',
    render: (value: boolean) => (value === true ? 'Yes' : 'No'),
    key: 'isPublished',
  },
  {
    title: 'Created At',
    dataIndex: ['createdAt'],
    key:  'createdAt',
    render: renderTableDate,
    sorter: true,
  },
];

const EventTable = () => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Helmet>
        <title>Events Table | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <Table
        defaultSort={{ createdAt: 'desc' }}
        tableId={'eventsTable'}
        exportApiCall={{
          excel: Api.events.exportByExcel,
          word: Api.events.exportByWord,
        }}
        deleteApiCall={Api.events.delete}
        allowDelete={true}
        restoreApiCall={Api.events.restore}
        exportFields={[
          {
            label: 'Name',
            path: 'title',
          },
          {
            label: 'Date',
            path: 'createdAt',
          },
          {
            label: 'Venue',
            path: 'venue.title',
          },
          {
            label: 'Phone Numbers',
            path: 'venue.phones',
          },
          {
            label: 'Text',
            path: 'text',
          },
        ]}
        actions={(record: any) => (
          <Link to={`/events/update/${record._id}`}>View</Link>
        )}
        filterDefinitions={[
          {
            key: 'title',
            type: 'search',
            label: 'Title',
          },
          {
            key: 'venue',
            type: 'multiValue',
            label: 'Venue',
            apiFetchCall: Api.venues.list,
            labelField: 'title',
            apiAddCall: Api.venues.create,
          },
          {
            key: 'type',
            type: 'multiValue',
            label: 'Type',
            apiFetchCall: Api.eventTypes.list,
            labelField: 'name',
            apiAddCall: Api.eventTypes.create,
          },
          {
            key: 'dateV2',
            type: 'dateRange',
            label: 'Date',
          },
        ]}
        Filter={BasicTableFilter}
        apiCall={Api.events.table}
        columns={columns}
      />
    </Space>
  );
};

export default EventTable;
