import { Editor } from '@tiptap/react';
import { Input, Space, Switch } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';

type LinkPopupFormType = {
  url?: string;
  isTargetNewTab?: boolean;
};

type LinkPopupProps = {
  url?: string;
  isTargetNewTab?: boolean;
  editor: Editor;
};

const LinkPopup = ({ url, isTargetNewTab = true, editor }: LinkPopupProps) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<LinkPopupFormType>({
    defaultValues: {
      url,
      isTargetNewTab,
    },
  });

  const onSubmit = (data: LinkPopupFormType) => {
    const pattern = /^(http|https):\/\/[^ "]+$/;
    if (data.url) {
      const isValid = pattern.test(data.url);
      if (isValid) {
        setValue('url', data.url);
        clearErrors('url');
        editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({
            href: data.url!,
            target: data.isTargetNewTab ? '_blank' : null,
          })
          .setUnderline()
          .run();
      } else {
        setError('url', { message: 'Enter a valid URL' });
      }
    } else {
      editor.chain().focus().unsetLink().unsetUnderline().run();
    }
  };

  return (
    <div className="flex flex-col items-start w-64 p-2 border border-transparent bg-slate-800 rounded-md text-xs">
      <form onSubmit={handleSubmit(onSubmit)} className="space-x-2">
        <Space.Compact>
          <Input
            placeholder="Enter URL"
            defaultValue={url}
            onChange={(e) => {
              const value = e.target.value;
              setValue('url', value);
            }}
          />
          <button
            className="px-2 text-white disabled:bg-gray-500 hover:bg-blue-400 bg-blue-500 rounded-r-md cursor-pointer"
            type="submit"
            disabled={!url && !watch('url')}
          >
            Submit
          </button>
        </Space.Compact>
        <div className="flex">
          {errors.url && (
            <span className="flex-start text-red-500">
              {errors.url.message}
            </span>
          )}
        </div>
        <div className="pt-1">
          <label>
            <span className="font-bold text-white">Open in new tab </span>
            <Switch
              {...register('isTargetNewTab')}
              defaultChecked={isTargetNewTab}
              onChange={(e) => {
                setValue('isTargetNewTab', e);
              }}
              disabled={!isTargetNewTab || !watch('url')}
            />
          </label>
        </div>
      </form>
    </div>
  );
};

export default LinkPopup;
