import React from 'react';
import { Routes } from '../routes';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

export const Breadcrumbs = () => {
  //const breadcrumbs: any = useBreadcrumbs(Routes);

  return null;
  //   return (
  //     // <Breadcrumb>
  //     //   {breadcrumbs
  //     //     .filter((crumb: any) => !!crumb.component)
  //     //     .map((crumb: any) => (
  //     //       <Breadcrumb.Item key={crumb.key}>
  //     //         <Link to={crumb.key}>{crumb.title}</Link>
  //     //       </Breadcrumb.Item>
  //     //     ))}
  //     // </Breadcrumb>
  //   );
};
