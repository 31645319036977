import { Editor } from '@tiptap/react';
import toSelect, { Headings } from './Select';
import toColorPicker, { Colors } from './Color';
import toHeaderItem, { Alignments, History, Text } from './Item';
import { useEffect, useState } from 'react';
import LinkMenu from '../Link';
import ImageItem from '../image';
import YoutubeMenu from '../youtube';

type HeaderProps = {
  editor: Editor | null;
  characters: number;
  words: number;
};

export default function Header({ editor, characters, words }: HeaderProps) {
  const [attributes, setAttributes] = useState<Record<string, string>>();
  useEffect(() => {
    setAttributes({
      color: editor?.getAttributes('textStyle')?.color,
      backgroundColor: editor?.getAttributes('highlight')?.color,
    });
  }, [editor, editor?.state.selection.anchor]);
  if (!editor) {
    return null;
  }
  const history = History(editor),
    headings = Headings(editor),
    colors = Colors(editor),
    text = Text(editor),
    alignments = Alignments(editor);
  return (
    <div className="flex flex-row w-full gap-6 overflow-hidden rounded-md bg-slate-800 p-2">
      <div className="flex flex-row gap-1">{toHeaderItem(history)}</div>
      <div className="flex flex-row gap-1">
        {toSelect(headings)}
        {toColorPicker(colors, attributes)}
        {toHeaderItem(text)}
      </div>
      <div className="flex flex-row gap-1">{toHeaderItem(alignments)}</div>
      <div className="flex flex-row gap-1">
        <LinkMenu editor={editor} />
        <ImageItem editor={editor} />
        <YoutubeMenu editor={editor} />
      </div>
      <div className="flex flex-col gap-1 text-gray-100 ml-auto">
        <span className="text-xs font-semibold">
          {words} {words === 1 ? 'word' : 'words'}
        </span>
        <span className="text-xs font-semibold">
          {characters} {characters === 1 ? 'character' : 'characters'}
        </span>
      </div>
    </div>
  );
}
