import { Descriptions, Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';

import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Routes } from '../routes';
import { Breadcrumbs } from './Breadcrumbs';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate } from 'react-router-dom';

export const ModuleHeader = () => {
  const breadcrumbs: any = useBreadcrumbs(Routes);
  const current = breadcrumbs[breadcrumbs.length - 1];
  const [data, setData]: [any, any] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (current.match?.route && current.match.route.dataRoute)
      current.match.route.dataRoute().then((res: any) => {
        setData(res.data);
      });
    else setData([]);
  }, [current.key]);
  if (!current.match?.route) return null;
  return (
    <div style={{ padding: 7, marginTop: 16, background: 'white' }}>
      <Breadcrumbs />
      {!current?.match?.route?.hidePageHeader && (
        <PageHeader
          className="site-page-header"
          onBack={() => navigate(-1)}
          title={current.match.route.title}
          extra={current.match.route.buttons || []}
        >
          <Descriptions size="small" column={3}>
            {data.map((i: Record<string, string>) => (
              <Descriptions.Item key={i.label} label={i.label}>
                {i.value}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </PageHeader>
      )}
    </div>
  );
};
