import React, { useState } from 'react';
import '../styles/ck-editor.css';
import { Button, Modal } from 'antd';

type OldHtmlTextPreviewModalProps = {
  text: string;
};
const OldHtmlTextPreviewModal: React.FC<OldHtmlTextPreviewModalProps> = ({
  text,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="default" onClick={showModal}>
        Preview old text
      </Button>
      <Modal
        onCancel={handleCancel}
        width={1000}
        open={isModalOpen}
        footer={null}
      >
        <div
          key={text}
          className="ck-content w-full"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Modal>
    </>
  );
};

export default OldHtmlTextPreviewModal;
