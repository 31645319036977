import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../Table';
import { Api } from '../../api';
import { Space } from 'antd';
import BasicTableFilter from '../BasicTableFilter';
import { Helmet } from 'react-helmet';
import { priorityOptions } from './PeopleForm';

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: true,
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: true,
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    sorter: true,
  },
  {
    title: 'Job Title',
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    sorter: true,
  },
  {
    title: 'Category',
    dataIndex: ['category', 'name'],
    key: 'category',
    sorter: true,
  },
  {
    title: 'Tags',
    dataIndex: 'tagsV2',
    key: 'tagsV2',
    sorter: true,
    render: (value: string[]) =>
      value.length <= 1 ? (
        value
      ) : (
        <>
          {value[0]}{' '}
          <span
            style={{
              backgroundColor: '#808080',
              borderRadius: '100%',
              fontSize: 10,
              padding: 3,
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            +{value.length - 1}
          </span>
        </>
      ),
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    sorter: true,
    render: (value: number) => {
      const { label, backgroundColor } = priorityOptions[value ?? 0];
      return (
        <div
          style={{
            padding: '2px 6px',
            borderRadius: 4,
            backgroundColor,
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          <span>{label}</span>
        </div>
      );
    },
  },
  {
    title: 'Emails',
    dataIndex: 'emails',
    key: 'emails',
    render: (value: string[]) =>
      value.length <= 1 ? (
        value
      ) : (
        <>
          {value[0]}{' '}
          <span
            style={{
              backgroundColor: '#808080',
              borderRadius: '100%',
              padding: 4,
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            +{value.length - 1}
          </span>
        </>
      ),
  },
  {
    title: 'Phones',
    dataIndex: 'phones',
    key: 'phones',
    render: (value: string[]) =>
      value.length <= 1 ? (
        value
      ) : (
        <>
          {value[0]}{' '}
          <span
            style={{
              backgroundColor: '#808080',
              borderRadius: '100%',
              fontSize: 10,
              padding: 3,
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            +{value.length - 1}
          </span>
        </>
      ),
  },
];

const PeopleTable = () => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Helmet>
        <title>People Table | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <Table
        tableId={'peoplesTable'}
        deleteApiCall={Api.peoples.delete}
        allowDelete={true}
        restoreApiCall={Api.peoples.restore}
        actions={(record: any) => (
          <Link to={`/peoples/single/${record._id}`}>View</Link>
        )}
        filterDefinitions={[
          {
            key: 'firstName',
            type: 'search',
            label: 'First Name',
          },
          {
            key: 'lastName',
            type: 'search',
            label: 'Last Name',
          },
          {
            key: 'company',
            type: 'search',
            label: 'Company',
          },
          {
            key: 'priority',
            type: 'priority',
            label: 'Priority',
          },
          {
            key: 'area',
            type: 'multiValue',
            label: 'Area',
            apiFetchCall: Api.areas.list,
            apiAddCall: Api.areas.create,
          },
          {
            key: 'category',
            type: 'multiValue',
            label: 'Category',
            apiFetchCall: Api.categories.list,
            apiAddCall: Api.categories.create,
          },
          {
            key: 'tags',
            type: 'multiValue',
            label: 'Tags',
            apiFetchCall: Api.tags.list,
            apiAddCall: Api.tags.create,
          },
          {
            key: 'isEntity',
            type: 'switch',
            label: 'Entity Only',
          },
        ]}
        Filter={BasicTableFilter}
        apiCall={Api.peoples.table}
        exportApiCall={{
          csv: Api.peoples.exportByCSV,
        }}
        columns={columns}
      />
    </Space>
  );
};

export default PeopleTable;
