import { Row } from 'antd';
import { useEffect, useState } from 'react';
import { Api } from '../api';

const Dashboard = () => {
  const [data, setData] = useState({
    websiteUsersCount: 0,
    coursesCount: 0,
    eventsCount: 0,
    ordersCount: 0,
    assessmentAverages: { averageEngineering: 0, averageManagement: 0 },
    egroupRegistrations: 0,
    webinarRegistrations: 0,
    coursesRegistration: 0,
    assessmentsTaken: 0,
  });
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState([null, null]);

  const onDateChange = (dates: any, dateString: any) => {
    setDate(dates);
  };

  // const fetchData = () => {
  //   Api.dashboard
  //     .getDashboardData({
  //       date: {
  //         from: date && date[0],
  //         to: date && date[1],
  //       },
  //     })
  //     .then((res) => {
  //       setData(res.data);
  //       setLoading(false);
  //     });
  // };
  // useEffect(fetchData, []);

  return (
    <Row gutter={[16, 24]}>
      {/* <Col span={24}>
        <Card>
          <p>Data Range</p>
          <DatePicker.RangePicker
            //@ts-ignore
            value={date}
            format={"DD-MM-YYYY"}
            ranges={{
              Today: [moment(), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
              "This Year": [moment().startOf("year"), moment().endOf("year")],
              "Last Year": [
                moment().subtract(1, "year").startOf("year"),
                moment().subtract(1, "year").endOf("year"),
              ],
            }}
            onChange={onDateChange}
          />
          <Button onClick={fetchData} type="default" style={{ marginLeft: 20 }}>
            Calculate dashboard
          </Button>
        </Card>
      </Col>
      <Col span={6}>
        <Card type="inner">
          <Statistic loading={loading} title="Courses" value={data.coursesCount} />
          <Link to="/courses">
            <Button style={{ marginTop: 16 }} type="default">
              View
            </Button>
          </Link>
        </Card>
      </Col>
      <Col span={6}>
        <Card type="inner">
          <Statistic loading={loading} title="Events" value={data.eventsCount} />
          <Link to="/events">
            <Button style={{ marginTop: 16 }} type="default">
              View
            </Button>
          </Link>
        </Card>
      </Col>
      <Col span={6}>
        <Card type="inner">
          <Statistic loading={loading} title="Registered Users" value={data.websiteUsersCount} />
          <Link to="/website-user">
            <Button style={{ marginTop: 16 }} type="default">
              View
            </Button>
          </Link>
        </Card>
      </Col>
      <Col span={6}>
        <Card type="inner">
          <Statistic loading={loading} title="Orders" value={data.ordersCount} />
          <Link to="/orders">
            <Button style={{ marginTop: 16 }} type="default">
              View
            </Button>
          </Link>
        </Card>
      </Col>
      <Col span="8">
        <Card>
          <Statistic
            style={{ textAlign: "center" }}
            title="Assessments Taken"
            value={data.assessmentsTaken}
            valueStyle={{ color: "#4091F7", fontSize: 48 }}
          />
        </Card>
      </Col>
      <Col span="8">
        <Card>
          <Statistic
            style={{ textAlign: "center" }}
            title="Engineering Assessment Average Score"
            value={data.assessmentAverages?.averageEngineering}
            valueStyle={{ color: "#4091F7", fontSize: 48 }}
            precision={2}
            suffix="/10"
          />
        </Card>
      </Col>
      <Col span="8">
        <Card>
          <Statistic
            style={{ textAlign: "center" }}
            precision={2}
            title="Management Assessment Average Score"
            value={data.assessmentAverages?.averageManagement}
            valueStyle={{ color: "#4091F7", fontSize: 48 }}
            suffix="/10"
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card type="inner">
          <Statistic
            style={{ textAlign: "center" }}
            loading={loading}
            prefix={<DollarOutlined />}
            valueStyle={{ fontSize: 48, color: "#764E8C" }}
            title="E-Group Purchases"
            value={data.egroupRegistrations}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card type="inner">
          <Statistic
            style={{ textAlign: "center" }}
            loading={loading}
            prefix={<DollarOutlined />}
            valueStyle={{ fontSize: 48, color: "#F29B39" }}
            title="Webinar Registration"
            value={data.webinarRegistrations}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card type="inner">
          <Statistic
            style={{ textAlign: "center" }}
            loading={loading}
            prefix={<DollarOutlined />}
            valueStyle={{ fontSize: 48, color: "#F7C745" }}
            title="Course Registration"
            value={data.coursesRegistration}
          />
        </Card>
      </Col> */}
    </Row>
  );
};

export default Dashboard;
