import { Editor } from '@tiptap/react';
import { Input, Space } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';

type YoutubePopupFormType = {
  url?: string;
};

type YoutubePopupProps = {
  closePopup: () => void;
  editor: Editor;
};

const YoutubePopup = ({ closePopup, editor }: YoutubePopupProps) => {
  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<YoutubePopupFormType>();

  const onSubmit = (data: YoutubePopupFormType) => {
    if (data.url) {
      editor
        .chain()
        .focus()
        .setYoutubeVideo({
          src: data.url,
        })
        .createParagraphNear()
        .run();
      closePopup();
    }
  };

  return (
    <div className="flex flex-col items-start w-64 p-2 border border-transparent bg-slate-800 rounded-md text-xs">
      <form onSubmit={handleSubmit(onSubmit)} className="space-x-2">
        <Space.Compact>
          <Input
            placeholder="Enter URL"
            onChange={(e) => {
              const value = e.target.value,
                pattern = /^https:\/\/www.youtube.com[^ "]+$/;
              if (value) {
                const isValid = pattern.test(value);
                if (isValid) {
                  setValue('url', value);
                  clearErrors('url');
                } else {
                  setError('url', { message: 'Enter a valid URL' });
                }
              } else {
                clearErrors('url');
              }
            }}
          />
          <button
            className="px-2 text-white disabled:bg-gray-500 hover:bg-blue-400 bg-blue-500 rounded-r-md cursor-pointer"
            type="submit"
            disabled={!watch('url')}
          >
            Submit
          </button>
        </Space.Compact>
        <div className="flex">
          {errors.url && (
            <span className="flex-start text-red-500">
              {errors.url.message}
            </span>
          )}
        </div>
      </form>
    </div>
  );
};

export default YoutubePopup;
