import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../Table';
import { Api } from '../../api';
import { Space } from 'antd';
import BasicTableFilter from '../BasicTableFilter';
import { Helmet } from 'react-helmet';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: '20%',
  },
];

const ArticleTypeTable = () => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Helmet>
        <title>articleTypes Table | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <Table
        tableId={'articleTypesTable'}
        deleteApiCall={Api.articleTypes.delete}
        allowDelete={true}
        restoreApiCall={Api.articleTypes.restore}
        actions={(record: any) => (
          <Link to={`/articles/types/update/${record._id}`}>View</Link>
        )}
        Filter={BasicTableFilter}
        apiCall={Api.articleTypes.table}
        columns={columns}
      />
    </Space>
  );
};

export default ArticleTypeTable;
