import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { ImagesHelpers } from '../../helpers/images';
import { PlusIcon } from 'lucide-react';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const FileInputV2: React.FC<any> = ({ onChange, format = [], file }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  format.push('.png', '.jpg', '.jpeg', '.gif', '.mp4');

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  useEffect(() => {
    if (file) {
      if (typeof file === 'string') {
        const fileObj = {
          uid: '1',
          name: '',
          url: ImagesHelpers.formatUrl(file),
        };
        setFileList([fileObj]);
      } else {
        getBase64(file).then((value) => {
          file.url = file.preview = value;
          setFileList([file]);
        });
      }
    } else setFileList([]);
  }, [file]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  return (
    <>
      {fileList.length < 1 ? (
        <>
          <input
            accept={format.join(',')}
            type="file"
            className="hidden"
            id="file-upload"
            onChange={(e) => {
              onChange(e.target.files);
            }}
          />
          <label
            className="bg-gray-100 border border-dashed rounded-lg w-fit aspect-square p-5 flex flex-col items-center justify-center border-gray-300"
            htmlFor="file-upload"
          >
            <PlusIcon />
            Upload
          </label>
        </>
      ) : (
        <div style={{ position: 'relative', borderRadius: 10 }}>
          <img
            alt="pl"
            style={{ width: 88, height: 88, borderRadius: 10 }}
            src={fileList[0].url}
          />
          <div
            className="image-overlay"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 88,
              height: 88,
              position: 'absolute',
              left: 0,
              top: 0,
              borderRadius: 10,
            }}
          >
            <EyeOutlined
              onClick={() => {
                handlePreview(fileList[0]);
              }}
              style={{ color: 'white', cursor: 'pointer', marginRight: 10 }}
            />
            <DeleteOutlined
              onClick={() => {
                onChange([]);
              }}
              style={{ color: 'white', cursor: 'pointer' }}
            />
          </div>
        </div>
      )}
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};

export default FileInputV2;
