import { BgColorsOutlined, FontColorsOutlined } from '@ant-design/icons';
import { Editor } from '@tiptap/react';
import { Color } from 'antd/es/color-picker';
import { ColorPicker } from 'antd/lib';

export type HeaderColorPicker = {
  name: string;
  icon: any;
  disabled: boolean;
  attrKey?: string;
  defaultColor?: string | null;
  onClick: (value: Color, hex: string) => void;
};

export const Colors = (editor: Editor): HeaderColorPicker[] => [
  {
    name: 'textColor',
    icon: FontColorsOutlined,
    defaultColor: '#000000',
    attrKey: 'color',
    disabled: !editor.can().chain().focus().setColor('color').run(),
    onClick: (v, hex) => editor.chain().focus().setColor(hex).run(),
  },
  {
    name: 'backgroundColor',
    icon: BgColorsOutlined,
    attrKey: 'backgroundColor',
    defaultColor: '#ffffff',
    disabled: !editor
      .can()
      .chain()
      .focus()
      .setHighlight({ color: 'color' })
      .run(),
    onClick: (v, hex) =>
      hex === '#ffffff'
        ? editor.chain().focus().unsetHighlight().run()
        : editor.chain().focus().toggleHighlight({ color: hex }).run(),
  },
];

export default function toColorPicker(
  items: HeaderColorPicker[],
  attr: Record<string, string> = { color: '#000000' },
) {
  const presets = [
    {
      label: '',
      colors: [
        '#ffffff',
        '#000000',
        '#DF536B',
        '#61D04F',
        '#2297E6',
        '#28E2E5',
        '#CD0BBC',
        '#F5C710',
        '#9E9E9E',
      ],
      defaultOpen: true,
    },
  ];
  return items.map((item) => (
    <div className="flex flex-row gap-1" key={item.name}>
      <item.icon style={{ color: item.disabled ? 'grey' : 'white' }} />
      <ColorPicker
        value={
          item.attrKey
            ? attr[item.attrKey] ?? item.defaultColor
            : item.defaultColor
        }
        defaultFormat="hex"
        onChange={item.onClick}
        disabled={item.disabled}
        presets={presets}
      />
    </div>
  ));
}
