import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../Table';
import { Api } from '../../api';
import { Image, Space } from 'antd';
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from '../BasicTableFilter';
import { ImagesHelpers } from '../../helpers/images';
import Paragraph from 'antd/es/typography/Paragraph';

const columns = [
  {
    title: 'Title',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'Link',
    dataIndex: 'path',
    key: 'path',
    width: '40%',
    render: (value: string) => {
      const link = ImagesHelpers.formatUrl(value);
      return <Paragraph copyable>{link}</Paragraph>;
    },
  },
  {
    title: 'Image',
    dataIndex: 'path',
    key: 'path',
    sorter: true,
    width: '20%',
    render: (value: string) => {
      return (
        <Image
          width={100}
          height={100}
          style={{ objectFit: 'cover' }}
          src={ImagesHelpers.formatUrl(value)}
        />
      );
    },
  },
];

const FileTable = () => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Table
        defaultSort={{ createdAt: 'desc' }}
        tableId={'files'}
        deleteApiCall={Api.files.delete}
        allowDelete={true}
        restoreApiCall={Api.files.restore}
        actions={(record: any) => (
          <Link to={`/files/update/${record._id}`}>View</Link>
        )}
        Filter={BasicTableFilter}
        filterDefinitions={[
          {
            key: 'name',
            type: 'search',
            label: 'Title',
          },
        ]}
        apiCall={Api.files.table}
        columns={columns}
      />
    </Space>
  );
};

export default FileTable;
