import { LoadingOutlined, PictureOutlined } from '@ant-design/icons';
import { Editor } from '@tiptap/react';
import { useEffect } from 'react';
import { useUpload } from 'react-use-upload';
import { Base_Url } from '../../api';
import { store } from '../../store';
import { ImagesHelpers } from '../../helpers/images';
import { Spin } from 'antd';

type ImageItemProps = {
  editor: Editor;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      /**
       * Add an image
       */
      setImage: (options: {
        src: string;
        alt?: string;
        title?: string;
        float?: string;
      }) => ReturnType;
      setImageAlignment: (
        alignment: 'left' | 'right' | 'center' | 'none',
      ) => ReturnType;
    };
  }
}

export default function ImageItem({ editor }: ImageItemProps) {
  let [upload, { loading, done, data }] = useUpload(({ files }) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    return {
      method: 'POST',
      url: `${Base_Url}/file/upload`,
      body: formData,
      headers: {
        Authorization: `Bearer ${store.getState().token}`,
      },
    };
  });

  useEffect(() => {
    if (done && data) {
      editor
        .chain()
        .focus()
        .setImage({ src: ImagesHelpers.formatUrlOnly(data.path) })
        .run();
    }
  }, [data, done]);

  return loading && !done ? (
    <div className="m-auto rounded-full">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
    </div>
  ) : (
    <div
      className={`m-auto not:disabled:hover:bg-slate-600 rounded-sm p-1 disabled:cursor-not-allowed`}
    >
      <label htmlFor="file-input" style={{ height: 3 }}>
        <span>
          <PictureOutlined style={{ color: 'white', height: 3 }} />
        </span>
      </label>
      <input
        id="file-input"
        type="file"
        accept=".png,.jpg,.jpeg"
        key={'image'}
        onInput={(e) => {
          if (e.currentTarget.files && e.currentTarget.files[0]) {
            upload({ files: e.currentTarget.files });
          }
        }}
        className={'hidden w-0 h-0'}
      ></input>
    </div>
  );
}
