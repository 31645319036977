import React from "react";
import { Link } from "react-router-dom";
import { Table } from "../Table";
import { Api } from "../../api";
import { Space } from "antd";
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from "../BasicTableFilter";

const columns = [
	{
		title: "First Name",
		dataIndex: "firstName",
		key: "firstName",
		sorter: true,
	},
	{
		title: "Last Name",
		dataIndex: "lastName",
		key: "lastName",
		sorter: true,
	},
	{
		title: "Email",
		dataIndex: "email",
		key: "email",
		sorter: true,
	},
	{
		title: "Message",
		dataIndex: "message",
		key: "message",
		ellipsis: true,
	},
];
const filterDefinitions = [
	{
		key: "firstName",
		type: "search",
		label: "First Name",
		span: 4,
	},
	{
		key: "lastName",
		type: "search",
		label: "Last Name",
		span: 4,
	},
	{
		key: "email",
		type: "search",
		label: "Email",
		span: 4,
	},
];

const ContactFormTable = () => {
	return (
		<Space style={{ width: "100%" }} direction="vertical">
			<Table
				filterDefinitions={filterDefinitions}
				tableId={"contactFormsTable"}
				deleteApiCall={Api.contact.delete}
				allowDelete={true}
				restoreApiCall={Api.contact.restore}
				actions={(record: any) => (
					<Link to={`/contact/single/${record._id}`}>View</Link>
				)}
				Filter={BasicTableFilter}
				apiCall={Api.contact.table}
				columns={columns}
			/>
		</Space>
	);
};

export default ContactFormTable;
