import './styles.css';
import React, { useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import Header from './Header';
import { extensions } from './extensions';

export type BlockEditorType = { JSON: object; html: string };

type BlockEditorProps = {
  content?: string;
  onChange: (content: BlockEditorType) => void;
};

const BlockEditor = ({ content, onChange }: BlockEditorProps) => {
  const editor = useEditor(
      {
        autofocus: true,
        content,
        extensions,
      },
      [],
    ),
    characterCount = editor?.storage.characterCount || {
      characters: () => 0,
      words: () => 0,
    };

  useEffect(() => {
    //if (editor && content) editor.commands.setContent(content);
    const timeout = setTimeout(() => {
      if (editor) {
        if (editor.isEmpty) onChange({ JSON: {}, html: '' });
        else onChange({ JSON: editor.getJSON(), html: editor.getHTML() });
      }
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, [editor?.state.doc.content]);

  useEffect(() => {
    if (editor && content) {
      if (editor.state.doc.textContent.length === 0) {
        editor.commands.setContent(content);
      }
    }
  }, [editor, content]);

  return (
    <div className="flex h-full border rounded-md">
      <div className="relative flex flex-col flex-1 h-full overflow-hidden">
        <Header
          editor={editor}
          characters={characterCount.characters()}
          words={characterCount.words()}
        />
        <EditorContent
          editor={editor}
          className="flex-1 overflow-y-auto p-10"
        />
      </div>
    </div>
  );
};

export default BlockEditor;
