import { Button, Col, Input, message, Result, Row, Space } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../api';
import { useSubmitForm } from '../../helpers/forms';
import { useLoadFormData } from '../../hooks/useLoadFormData';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { ImagesHelpers } from '../../helpers/images';
import FileInputV2 from '../fileUploadV2/FileInputV2';
import Paragraph from 'antd/es/typography/Paragraph';

const FileForm = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  let navigate = useNavigate();
  const onSuccess = () => {
    message.success('Saved');
    navigate('/files');
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.files.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.files.create,
    updateApiCall: Api.files.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });
  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="default">
            <Link to="/files">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="white-container" onSubmit={handleSubmit(submit)}>
      <Space size="middle" style={{ width: '100%' }} direction="vertical">
        <Row gutter={4}>
          <Col span={6}>
            <label htmlFor={'name'}>Title</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} id={'name'} />}
              rules={{ required: true }}
              name="name"
            />
            <FieldErrorMessage errors={errors} name="name" />
          </Col>
          <Col>
            <label htmlFor={'path'}>Link</label>
            <Controller
              control={control}
              render={({ field }) =>
                typeof field.value === 'string' ? (
                  <div className="py-1 rounded-md text-sm ">
                    <Paragraph copyable>
                      {ImagesHelpers.formatUrl(field.value)}
                    </Paragraph>
                  </div>
                ) : (
                  <Input disabled />
                )
              }
              rules={{ required: false }}
              name="path"
            />
            <FieldErrorMessage errors={errors} name="link" />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor={'path'}>Import File</label>
            <Controller
              name="path"
              control={control}
              render={({ field }) => (
                <FileInputV2
                  onChange={(files: any) => {
                    if (files[0]) field.onChange(files[0]);
                    else field.onChange();
                  }}
                  file={field.value}
                  format={['.pdf']}
                />
              )}
              rules={{ required: true }}
            />
            <FieldErrorMessage errors={errors} name="path" />
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button
        loading={loading || loadingSubmit}
        htmlType="submit"
        role={'submit'}
      >
        Submit
      </Button>
    </form>
  );
};

export default FileForm;
