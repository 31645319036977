import { message } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Helpers } from "../helpers";
import { Redux_Actions, store } from "../store";

export const Base_Url = Helpers.isProduction
	? process.env.REACT_APP_PRODUCTION_API_URL
	: process.env.REACT_APP_SIMULATE_PRODUCTION === "true"
		? process.env.REACT_APP_PRODUCTION_API_URL
		: process.env.REACT_APP_LOCAL_API_URL;
const axiosInstance = axios.create({
	baseURL: Base_Url,
	timeout: 1 * 60 * 60 * 60 * 60,
	withCredentials: false,
});
axiosInstance.interceptors.request.use(
	async (config) => {
		config.headers.Authorization = `Bearer ${store.getState().token}`;
		return config;
	},
	(error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	(error: AxiosError) => {
		// message.error()
		if (error.response) {
			if (error.response.status === 401) {
				message.error("You have been logged out from the server");
				store.dispatch({ type: Redux_Actions.logout });
			}
			if (error.response.data) {
				//@ts-ignore
				let err = error.response.data.message;
				if (err) {
					if (Array.isArray(err) && error.response.status === 400) {
						err.forEach((i) => {
							message.error(i.msg);
						});
					} else if (err) {
						message.error(err);
					}
				} else message.error(error.toString());
			} else {
				message.error(error.toString());
			}
		} else {
			message.error(error.toString());
		}

		return Promise.reject(error);
	},
);

const defaultApiRoutes: (basePath: string) => {
	create: (args: any) => Promise<AxiosResponse>;
	table: (args: any) => Promise<AxiosResponse>;
	single: (args: any) => Promise<AxiosResponse>;
	update: (args: any) => Promise<AxiosResponse>;
	delete: (args: any) => Promise<AxiosResponse>;
	exportByCSV: (args: any) => Promise<AxiosResponse>;
	exportByExcel: (args: any) => Promise<AxiosResponse>;
	exportByWord: (args: any) => Promise<AxiosResponse>;
	restore: (args: any) => Promise<AxiosResponse>;
	statistics: () => Promise<AxiosResponse>;
	list: () => Promise<AxiosResponse>;
} = (basePath: string) => {
	return {
		create: ({ ...args }: { args: any }): Promise<AxiosResponse> =>
			axiosInstance.post(`/${basePath}/create`, args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get(`/${basePath}/table`, { params: args }),
		exportByCSV: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get(`/${basePath}/export/csv`, {
				params: args,
				responseType: "blob",
			}),
		exportByExcel: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get(`/${basePath}/export/excel`, {
				params: args,
				responseType: "blob",
			}),
		exportByWord: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get(`/${basePath}/export/word`, {
				params: args,
				responseType: "blob",
			}),
		single: (id: any): Promise<AxiosResponse> =>
			axiosInstance.get(`/${basePath}/single/${id}`),
		update: ({ id, ...args }: { id: any; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/${basePath}/single/${id}`, { ...args }),
		list: (): Promise<AxiosResponse> => axiosInstance.get(`/${basePath}/list`),
		delete: (id: any): Promise<AxiosResponse> =>
			axiosInstance.delete(`/${basePath}/single/${id}`),
		restore: (id: any): Promise<AxiosResponse> =>
			axiosInstance.post(`/${basePath}/single/${id}`),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get(`/${basePath}/statistics`),
	};
};

export let Api = {
	upload: ({ formData }: any): Promise<AxiosResponse> =>
		axiosInstance.post("/file/upload", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}),
	login: ({
		username,
		password,
	}: {
		username: string;
		password: string;
	}): Promise<any> =>
		axiosInstance.post("/login", {
			email: username,
			password,
		}),
	logout: (): Promise<AxiosResponse> => axiosInstance.get("/logout"),
	orders: {
		...defaultApiRoutes("orders"),
	},
	peoples: defaultApiRoutes("peoples"),
	promoCodes: defaultApiRoutes("promoCodes"),
	folders: defaultApiRoutes("folders"),
	advertisements: defaultApiRoutes("advertisements"),
	articleTypes: defaultApiRoutes("articleTypes"),
	eventTypes: defaultApiRoutes("eventTypes"),
	articles: defaultApiRoutes("articles"),
	venues: defaultApiRoutes("venues"),
	areas: defaultApiRoutes("areas"),
	files: {
		...defaultApiRoutes("files"),
		create: (data: any) => {
			return axiosInstance.post("/files/create", data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
		},
		update: ({ id, ...data }: any) => {
			return axiosInstance.put(`/files/single/${id}`, data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
		},
	},
	users: {
		...defaultApiRoutes("users"),
		resetPassword: ({
			id,
			args,
		}: {
			id: any;
			args: any;
		}): Promise<AxiosResponse> =>
			axiosInstance.put(`/users/${id}/reset`, { ...args }),
	},
	dashboard: {
		getDashboardData: (args: any) =>
			axiosInstance.post("/dashboard", { ...args }),
	},
	websiteUsers: {
		...defaultApiRoutes("websiteUser"),
		resetPassword: ({
			id,
			args,
		}: {
			id: any;
			args: any;
		}): Promise<AxiosResponse> =>
			axiosInstance.put(`/websiteUser/reset/${id}`, { ...args }),
	},
	contact: defaultApiRoutes("contact"),
	newsletter: defaultApiRoutes("newsletters"),
	categories: defaultApiRoutes("categories"),
	tags: defaultApiRoutes("tags"),
	portfolios: defaultApiRoutes("portfolio"),
	pages: defaultApiRoutes("pages"),
	dataImports: defaultApiRoutes("dataImports"),
	events: defaultApiRoutes("events"),
	courses: {
		...defaultApiRoutes("courses"),
		singleWithRegistrants: (id: any): Promise<AxiosResponse> =>
			axiosInstance.get(`/courses/single-with-registrants/${id}`),
		addSlot: ({ id, data }: { id: any; data: any }): Promise<AxiosResponse> =>
			axiosInstance.post(`/courses/addSlot/${id}`, { data }),
		updateSlot: ({
			id,
			data,
		}: {
			id: any;
			data: any;
		}): Promise<AxiosResponse> =>
			axiosInstance.post(`/courses/updateSlot/${id}`, { data }),
		deleteSlot: ({
			id,
			data,
		}: {
			id: any;
			data: any;
		}): Promise<AxiosResponse> =>
			axiosInstance.post(`/courses/deleteCourseSlot/${id}`, { data }),
		addRegistrantToSlot: (courseId: string, slotId: string, userId: string) =>
			axiosInstance.post("/course/addRegistrantToSlot", {
				courseId,
				slotId,
				userId,
			}),
		addRegistrantToCourse: (courseId: string, userId: string) =>
			axiosInstance.post("/course/addRegistrantToCourse", {
				courseId,
				userId,
			}),
	},
	assessments: defaultApiRoutes("assessments"),
	// events: {
	// 	...defaultApiRoutes('events'),
	// 	getRegistrants: (id: any): Promise<AxiosResponse> => axiosInstance.get(`/events/registrants/${id}`),
	// 	addRegistrant: (eventId: string, userId: string) =>
	// 		axiosInstance.post('/events/addRegistrant', {
	// 			eventId,
	// 			userId,
	// 		}),
	// },
	blogs: defaultApiRoutes("blogs"),
};
