import React, { useEffect, useCallback, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Checkbox,
  Space,
  DatePicker,
  Select,
  Switch,
} from 'antd';
import { useForm } from 'react-hook-form';
import { FieldErrorMessage } from './FieldErrorMessage';
import SelectWithApiData from './SelectiWithApiData';
import DataExportButton from './DataExportButton';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

export type BasicTableFilterProp = {
  value: any;
  onChange: (value: any) => void;
  tableId: string;
  filterDefinitions?: any;
  deletedFilter?: boolean;
  exportFields?: { path: string; label: string }[];
  exportApiCall?: {
    csv?: (args: any) => Promise<AxiosResponse>;
    excel?: (args: any) => Promise<AxiosResponse>;
    word?: (args: any) => Promise<AxiosResponse>;
  };
};
const default_definitions = [
  {
    key: 'name',
    type: 'search',
    label: 'Name',
  },
];
const BasicTableFilter = ({
  value = {
    dateV2: [new Date(), new Date()],
  },
  onChange,
  filterDefinitions,
  deletedFilter = true,
  exportApiCall,
  exportFields,
  tableId,
}: BasicTableFilterProp) => {
  const handleDefault = (data: typeof value) => {
    if (data.dateV2) {
      if (data.dateV2.from) data.dateV2.from = dayjs(data.dateV2.from);
      if (data.dateV2.to) data.dateV2.to = dayjs(data.dateV2.to);
    }
    return data;
  };
  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({ defaultValues: handleDefault(value) });
  const [definitions, setDefinitions] = useState(
    filterDefinitions || default_definitions,
  );

  useEffect(() => {
    if (deletedFilter)
      setDefinitions([
        ...definitions,
        {
          key: 'showDeleted',
          type: 'showDeleted',
          excludeFromGenerator: true,
        },
      ]);
  }, [deletedFilter]);

  const clearFilter = () => {
    reset({ showDeleted: false as any });
    onChange({
      filter: {
        dateV2: { from: null, to: null },
      },
      definitions,
    });
  };

  const handleChange = useCallback(
    (v: any) => {
      onChange({
        filter: v,
        definitions,
      });
    },
    [onChange],
  );

  const renderDefinition = (definition: any) => {
    switch (definition.type) {
      case 'switch':
        return (
          <Col key={definition.key} span={definition.span || 6}>
            <div className="">
              <label className="mr-10" htmlFor={definition.key}>
                {definition.label}:{' '}
              </label>
              <div>
                <Switch
                  checked={!!watch(definition.key)}
                  onChange={(e) => {
                    setValue(definition.key, e);
                    onChange({
                      filter: { ...value, [definition.key]: e },
                      definitions,
                    });
                  }}
                  value={watch(definition.key, [])}
                />
              </div>
            </div>
            <FieldErrorMessage
              style={{ textAlign: 'center' }}
              errors={errors}
              name={definition.key}
              formatName={definition.label}
            />
          </Col>
        );
      case 'priority': {
        const priorityOptions = [
          { label: 'None', value: 0, backgroundColor: '#808080' },
          { label: 'Free', value: 1, backgroundColor: '#7844F0' },
          { label: 'Low', value: 2, backgroundColor: '#F2AB3C' },
          { label: 'Medium Minus', value: 3, backgroundColor: '#A9C880' },
          { label: 'Medium Plus', value: 4, backgroundColor: '#CCD143' },
          { label: 'High', value: 5, backgroundColor: '#2356DF' },
          { label: 'Very High', value: 6, backgroundColor: '#C53E2D' },
        ];

        return (
          <Col key={definition.key} span={definition.span || 6}>
            <div className="">
              <label className="mr-10" htmlFor={definition.key}>
                {definition.label}:{' '}
              </label>
              <Select
                onChange={(data: Record<string, string>[]) => {
                  const dataValue = data.map((entry) => entry.value);
                  setValue(definition.key, dataValue);
                  onChange({
                    filter: { ...value, [definition.key]: dataValue },
                    definitions,
                  });
                }}
                mode="multiple"
                labelInValue
                value={watch(definition.key, [])}
                style={{ width: '100%' }}
                labelRender={(label) => {
                  const priority = priorityOptions[label.value as number];
                  return (
                    <span
                      style={{
                        padding: '2px 6px',
                        borderRadius: 4,
                        backgroundColor: priority.backgroundColor,
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      {priority.label}
                    </span>
                  );
                }}
                optionRender={(option) => {
                  const priority = priorityOptions[option.value as number];
                  return (
                    <span
                      style={{
                        padding: '2px 6px',
                        borderRadius: 4,
                        backgroundColor: priority.backgroundColor,
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      {priority.label}
                    </span>
                  );
                }}
                options={priorityOptions}
              />
            </div>
            <FieldErrorMessage
              style={{ textAlign: 'center' }}
              errors={errors}
              name={definition.key}
              formatName={definition.label}
            />
          </Col>
        );
      }
      case 'multiValue':
        return (
          <Col key={definition.key} span={definition.span || 6}>
            <div className="">
              <label className="mr-10" htmlFor={definition.key}>
                {definition.label}:{' '}
              </label>
              <SelectWithApiData
                onChange={(data) => {
                  setValue(definition.key, data);
                  onChange({
                    filter: { ...value, [definition.key]: data },
                    definitions,
                  });
                }}
                value={watch(definition.key, [])}
                multiValue
                apiAddCall={definition.apiAddCall}
                labelField={definition.labelField}
                apiFetchCall={definition.apiFetchCall}
                dataMappingFunction={definition.dataMappingFunction}
              />
            </div>
            <FieldErrorMessage
              style={{ textAlign: 'center' }}
              errors={errors}
              name={definition.key}
              formatName={definition.label}
            />
          </Col>
        );

      case 'boolean':
        return (
          <Col
            key={definition.key}
            style={{ marginTop: 15 }}
            span={definition.span || 6}
          >
            <div className="">
              <label className="mr-10" htmlFor={definition.key}>
                {definition.label}:{' '}
              </label>
              <Checkbox
                checked={watch(definition.key)}
                onChange={(e) => {
                  setValue(definition.key, e.target.checked);
                  onChange({
                    filter: { ...value, [definition.key]: e.target.checked },
                    definitions,
                  });
                }}
              />
            </div>
          </Col>
        );
      case 'dateRange':
        return (
          <Col
            key={definition.key}
            style={{ marginTop: 15 }}
            span={definition.span || 6}
          >
            <div className="">
              <label className="mr-10" htmlFor={definition.key}>
                {definition.label}:{' '}
              </label>
              <DatePicker.RangePicker
                name={definition.key}
                value={[
                  watch(`${definition.key}.from`, undefined),
                  watch(`${definition.key}.to`, undefined),
                ]}
                placeholder={['From', 'To']}
                onChange={(data) => {
                  if (!data) {
                    setValue(definition.key, {});
                    onChange({
                      filter: { ...value, [definition.key]: null },
                      definitions,
                    });
                  } else {
                    setValue(definition.key, { from: data[0], to: data[1] });
                    onChange({
                      filter: {
                        ...value,
                        [definition.key]: { from: data[0], to: data[1] },
                      },
                      definitions,
                    });
                  }
                }}
              />
            </div>
          </Col>
        );
      default:
        return (
          <Col key={definition.key} span={definition.span || 6}>
            <div className="">
              <label className="mr-10" htmlFor={definition.key}>
                {definition.label}:{' '}
              </label>
              <Input
                value={watch(definition.key)}
                onChange={(e) => {
                  setValue(definition.key, e.target.value);
                  onChange({
                    filter: { ...value, [definition.key]: e.target.value },
                    definitions,
                  });
                }}
                id={definition.key}
              />
            </div>
            <FieldErrorMessage
              style={{ textAlign: 'center' }}
              errors={errors}
              name={definition.key}
              formatName={definition.label}
            />
          </Col>
        );
    }
  };

  return (
    <div className="white-container" style={{ marginBottom: 12 }}>
      <form onSubmit={handleSubmit(handleChange)}>
        <Space size="large" style={{ width: '100%' }} direction="vertical">
          <Row style={{ width: '100%' }} align="middle" gutter={12}>
            {definitions
              .filter((def: any) => !def.excludeFromGenerator)
              .map((def: any) => renderDefinition(def))}
          </Row>
          <Row justify="end">
            <Space size="small">
              <div className="">
                <label className="mr-10" htmlFor={'showDeleted'}>
                  Show Deleted
                </label>

                <Checkbox
                  checked={value.showDeleted}
                  onChange={(e) => {
                    setValue('showDeleted', e.target.checked);
                    onChange({
                      filter: { ...value, showDeleted: e.target.checked },
                      definitions,
                    });
                  }}
                />
              </div>
              <Button size="small" onClick={clearFilter} danger>
                Reset
              </Button>
              {exportApiCall && (
                <>
                  {exportApiCall.csv && (
                    <DataExportButton
                      exportFields={exportFields}
                      tableId={tableId}
                      apiCall={exportApiCall.csv}
                      label="Export By CSV"
                    />
                  )}
                  {exportApiCall.excel && (
                    <DataExportButton
                      exportFields={exportFields}
                      tableId={tableId}
                      apiCall={exportApiCall.excel}
                      label="Export By Excel"
                    />
                  )}
                  {exportApiCall.word && (
                    <DataExportButton
                      exportFields={exportFields}
                      tableId={tableId}
                      apiCall={exportApiCall.word}
                      label="Export By Word"
                    />
                  )}
                </>
              )}
            </Space>
          </Row>
        </Space>
      </form>
    </div>
  );
};

export default BasicTableFilter;
