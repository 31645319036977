import React from "react";
import { Table } from "../Table";
import { Api } from "../../api";
import { Space } from "antd";
import BasicTableFilter from "../BasicTableFilter";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

const columns = [
	{
		title: "Full Name",
		dataIndex: "fullName",
		key: "fullName",
		sorter: true,
		width: "30%",
	},
	{
		title: "Email",
		dataIndex: "email",
		key: "email",
		sorter: true,
		width: "30%",
	},
	{
		title: "Phone",
		dataIndex: "phone",
		key: "phone",
		sorter: true,
		width: "20%",
	},
	{
		title: "Whatsapp",
		dataIndex: "isWhatsappAccepted",
		key: "isWhatsappAccepted",
		sorter: true,
		width: "10%",
		render: (value: boolean) => {
			return value ? (
				<CheckCircleFilled style={{ color: "green" }} />
			) : (
				<CloseCircleFilled />
			);
		},
	},
	{
		title: "Mail",
		dataIndex: "isMailAccepted",
		key: "isMailAccepted",
		sorter: true,
		width: "10%",
		render: (value: boolean) => {
			return value ? (
				<CheckCircleFilled style={{ color: "green" }} />
			) : (
				<CloseCircleFilled />
			);
		},
	},
	{
		title: "France Whatsapp",
		dataIndex: "isFrenchWhatsappAccepted",
		key: "isFrenchWhatsappAccepted",
		sorter: true,
		width: "10%",
		render: (value: boolean) => {
			return value ? (
				<CheckCircleFilled style={{ color: "green" }} />
			) : (
				<CloseCircleFilled />
			);
		},
	},
	{
		title: "France Mail",
		dataIndex: "isFrenchMailAccepted",
		key: "isFrenchMailAccepted",
		sorter: true,
		width: "10%",
		render: (value: boolean) => {
			return value ? (
				<CheckCircleFilled style={{ color: "green" }} />
			) : (
				<CloseCircleFilled />
			);
		},
	},
];

const NewsletterTable = () => {
	return (
		<Space style={{ width: "100%" }} direction="vertical">
			<Table
				defaultSort={{ createdAt: -1 }}
				tableId={"newsletter-table"}
				deleteApiCall={Api.newsletter.delete}
				allowDelete={true}
				restoreApiCall={Api.newsletter.restore}
				Filter={BasicTableFilter}
				apiCall={Api.newsletter.table}
				columns={columns}
				filterDefinitions={[
					{
						key: "fullName",
						type: "search",
						label: "Full Name",
					},
					{
						key: "email",
						type: "search",
						label: "Email",
					},
					{
						key: "phone",
						type: "search",
						label: "Phone",
					},
					{
						key: "isMailAccepted",
						type: "switch",
						label: "Mail Only",
						span: 3,
					},

					{
						key: "isWhatsappAccepted",
						type: "switch",
						label: "Whatsapp Only",
						span: 3,
					},
					{
						key: "isFrenchMailAccepted",
						type: "switch",
						label: "French Mail Only",
						span: 4,
					},
					{
						key: "isFrenchWhatsappAccepted",
						type: "switch",
						label: "French Whatsapp Only",
						span: 4,
					},
				]}
			/>
		</Space>
	);
};

export default NewsletterTable;
