import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../Table';
import { Api } from '../../api';
import BasicTableFilter from '../BasicTableFilter';
import { ResetPasswordModal } from '../ResetPasswordModal';

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: true,
    width: '20%',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    sorter: true,
    width: '20%',
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    // filters: [
    //     { text: 'Male', value: 'male' },
    //     { text: 'Female', value: 'female' },
    // ],
    width: '20%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
];
const filterDefinition = [
  {
    key: 'firstName',
    type: 'search',
    label: 'First Name',
  },
  {
    key: 'lastName',
    type: 'search',
    label: 'Last Name',
  },
];
const UsersList = () => {
  return (
    <Table
      tableId="usersTable"
      columns={columns}
      deleteApiCall={Api.users.delete}
      allowDelete={true}
      restoreApiCall={Api.users.restore}
      filterDefinitions={filterDefinition}
      actions={(record: any) => (
        <>
          <Link to={`/users/update/${record._id}`}>View</Link>
          <ResetPasswordModal
            resetPasswordCall={Api.users.resetPassword}
            userId={record._id}
          />
        </>
      )}
      Filter={BasicTableFilter}
      apiCall={Api.users.table}
    />
  );
};

export default UsersList;
