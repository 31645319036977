import { Editor } from '@tiptap/react';
import { Select } from 'antd';

export type HeaderSelectItem = {
  label: string;
  value: number;
  disabled: boolean;
  isSelected: () => void;
  onClick: () => void;
};

export const Headings = (editor: Editor): HeaderSelectItem[] => [
  {
    label: 'Paragraph',
    value: 1,
    disabled: !editor.can().chain().focus().setParagraph().run(),
    isSelected: () => editor.isActive('paragraph'),
    onClick: () => editor.chain().focus().setParagraph().run(),
  },
  {
    label: 'Heading 1',
    value: 2,
    disabled: !editor.can().chain().focus().setHeading({ level: 1 }).run(),
    isSelected: () => editor.isActive('heading', { level: 1 }),
    onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
  },
  {
    label: 'Heading 2',
    value: 3,
    disabled: !editor.can().chain().focus().setHeading({ level: 2 }).run(),
    isSelected: () => editor.isActive('heading', { level: 2 }),
    onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
  },
  {
    label: 'Heading 3',
    value: 4,
    disabled: !editor.can().chain().focus().setHeading({ level: 3 }).run(),
    isSelected: () => editor.isActive('heading', { level: 3 }),
    onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
  },
];

export default function toSelect(items: HeaderSelectItem[]) {
  return (
    <Select
      value={items.find((item) => item.isSelected()) ?? items[0]}
      style={{ width: 120 }}
      labelInValue
      onChange={(item) => {
        if (item) items[item.value - 1].onClick();
      }}
      options={items}
    />
  );
}
