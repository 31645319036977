import { Button, Col, Input, message, Result, Row, Space } from "antd";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Api } from "../../api";
import { useSubmitForm } from "../../helpers/forms";
import { useLoadFormData } from "../../hooks/useLoadFormData";
import { FieldErrorMessage } from "../FieldErrorMessage";

const ContactFormForm = () => {
	let { id } = useParams();
	// const [notFound, setNotFound] = useState(false);
	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm();
	let navigate = useNavigate();
	const onSuccess = () => {
		message.success("Saved");
		navigate("/contact");
	};
	// const imageFields = [
	//     {
	//         name: "image",
	//         singleValue: true,
	//     }
	// ];

	const [notFound, loading] = useLoadFormData({
		apiCall: Api.contact.single,
		id,
		setValue: reset,
		//  imageFields
	});
	const [loadingSubmit, submit] = useSubmitForm({
		createApiCall: Api.contact.create,
		updateApiCall: Api.contact.update,
		onSuccess,
		//  imageFields,
		updateId: id,
	});

	if (notFound)
		return (
			<Result
				status="404"
				title="404"
				subTitle="Sorry, document does not exist"
				extra={
					<Button type="default">
						<Link to="/contact">Back to list</Link>
					</Button>
				}
			/>
		);

	return (
		<form className="white-container" onSubmit={handleSubmit(submit)}>
			<Space size="middle" style={{ width: "100%" }} direction="vertical">
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={6}>
						<label htmlFor={"firstName"}>First Name</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} readOnly />}
							rules={{ required: true }}
							name="firstName"
						/>
						<FieldErrorMessage errors={errors} name="lastName" />
					</Col>
					<Col span={6}>
						<label htmlFor={"lastName"}>Last Name</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} readOnly />}
							rules={{ required: true }}
							name="lastName"
						/>
						<FieldErrorMessage errors={errors} name="lastName" />
					</Col>
				</Row>
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={6}>
						<label htmlFor={"email"}>Email</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} readOnly id={"email"} />}
							rules={{ required: true }}
							name="email"
						/>
						<FieldErrorMessage errors={errors} name="email" />
					</Col>
				</Row>
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={12}>
						<label htmlFor={"message"}>Message</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Input.TextArea {...field} readOnly id={"message"} />
							)}
							rules={{ required: true }}
							name="message"
						/>
						<FieldErrorMessage errors={errors} name="message" />
					</Col>
				</Row>
			</Space>
			<div style={{ marginBottom: 10 }} />
			{/* <Button
				loading={loading || loadingSubmit}
				htmlType="submit"
				role={"submit"}
			>
				Submit
			</Button> */}
		</form>
	);
};

export default ContactFormForm;
