import { Button, Col, Input, message, Result, Row, Space } from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../api';
import { useSubmitForm } from '../../helpers/forms';
import { useLoadFormData } from '../../hooks/useLoadFormData';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { Helmet } from 'react-helmet';

const ArticleTypeForm = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  let navigate = useNavigate();
  const onSuccess = () => {
    message.success('Saved');
    navigate('/articles/types');
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.articleTypes.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.articleTypes.create,
    updateApiCall: Api.articleTypes.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="default">
            <Link to="/articleTypes">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="white-container" onSubmit={handleSubmit(submit)}>
      <Helmet>
        <title>Create articleType | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <Space size="middle" style={{ width: '100%' }} direction="vertical">
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={6}>
            <label htmlFor={'name'}>Name</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} id={'name'} />}
              rules={{ required: true }}
              name="name"
            />
            <FieldErrorMessage errors={errors} name="name" />
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button
        loading={loading || loadingSubmit}
        htmlType="submit"
        role={'submit'}
      >
        Submit
      </Button>
    </form>
  );
};

export default ArticleTypeForm;
