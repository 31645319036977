import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../Table';
import { Api } from '../../api';
import { Space } from 'antd';
import BasicTableFilter from '../BasicTableFilter';
import { Helmet } from 'react-helmet';
import { renderTableDate } from '../../helpers/table';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: true,
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
    sorter: true,
  },
  {
    title: 'Published',
    dataIndex: 'isPublished',
    render: (value: boolean) => (value === true ? 'Yes' : 'No'),
  },
  {
    title: 'Created At',
    dataIndex: ['createdAt'],
    render: renderTableDate,
    sorter: true,
  },
];

const ArticleTable = () => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Helmet>
        <title>Articles Table | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <Table
        defaultSort={{ createdAt: 'desc' }}
        tableId={'articlesTable'}
        deleteApiCall={Api.articles.delete}
        allowDelete={true}
        restoreApiCall={Api.articles.restore}
        actions={(record: any) => (
          <Link to={`/articles/update/${record._id}`}>View</Link>
        )}
        filterDefinitions={[
          {
            key: 'title',
            type: 'search',
            label: 'Title',
          },
        ]}
        Filter={BasicTableFilter}
        apiCall={Api.articles.table}
        columns={columns}
      />
    </Space>
  );
};

export default ArticleTable;
